import { useCallback, useMemo, useState } from 'react';
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import { ConnectorNames, connectorsByName } from 'utils/constants/connectors';
import StorageUtils from 'utils/storage';
import { WALLET } from 'utils/constants';
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector';
import {
  UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
  WalletConnectConnector,
} from '@web3-react/walletconnect-connector';
import { useAstroneWallet } from 'contexts/AstroneContext';
import { useMobileDetect } from './useMobileDetect';
import { getWeb3Instance } from 'helpers/web3';

const useAuth = () => {
  const { activate, deactivate } = useWeb3React();
  const { loginAstrone, logoutAstrone } = useAstroneWallet();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const detectWallet = useMobileDetect();
  const isMobile = detectWallet.isMobile();

  const web3 = getWeb3Instance(window);

  const login = useCallback(async (connectorID: ConnectorNames) => {
    setIsLoading(true);
    setError('');
    const connector = connectorsByName[connectorID];
    if (connectorID === ConnectorNames.Astrone) {
      if (!(window as any).astrone) {
        setError('Please install or unlock Astrone wallet');
        StorageUtils.setItem(WALLET, '');
        setIsLoading(false);
      } else {
        await loginAstrone().then((res) => {
          if (res) {
            StorageUtils.setItem(WALLET, connectorID);
            setIsLoading(false);
          } else {
            setError('Connecting Error. Try Again');
            StorageUtils.setItem(WALLET, '');
            setIsLoading(false);
          }
        });
      }
    } else if (
      connectorID === ConnectorNames.Injected ||
      connectorID === ConnectorNames.WalletConnect
    ) {
      StorageUtils.setItem(WALLET, connectorID);
      connector.walletConnectProvider = undefined;
      if (isMobile && connectorID === ConnectorNames.Injected && !web3) {
        window.open(process.env.REACT_APP_METAMASK_DEEPLINK);
      }
      await activate(connector, async (error: Error) => {
        StorageUtils.setItem(WALLET, '');
        if (error instanceof UnsupportedChainIdError) {
          setError('Unsupported Chain Id Error. Check your chain Id');
        } else if (error instanceof NoEthereumProviderError) {
          // eslint-disable-next-line no-console
          console.error('Provider Error', 'No provider was found');
          if (connectorID === ConnectorNames.Injected) {
            setError('Please install or unlock Metamask wallet');
          }
        } else if (
          error instanceof UserRejectedRequestErrorInjected ||
          error instanceof UserRejectedRequestErrorWalletConnect
        ) {
          setError('Connecting Error. Try Again');
          if (connector instanceof WalletConnectConnector) {
            const walletConnector = connector as WalletConnectConnector;
            walletConnector.walletConnectProvider = undefined;
          }
          // eslint-disable-next-line no-console
          console.error(
            'Authorization Error',
            'Please authorize to access your account'
          );
        } else {
          // eslint-disable-next-line no-console
          console.error(error.name, error.message);
        }
      });
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setError("Can't find connector");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logout = useCallback(() => {
    StorageUtils.setItem(WALLET, '');
    deactivate();
    logoutAstrone();
  }, [deactivate, logoutAstrone]);

  const context = useMemo(() => {
    return {
      login,
      logout,
      isLoading,
      error,
    };
  }, [login, logout, isLoading, error]);

  return context;
};

export default useAuth;
