import { Pagination } from 'antd';
import { Pool } from 'components/modules/pool';
import NoRecord from 'components/common/NoRecord';
import { ITEM_PER_PAGE } from 'utils/constants';
import { Pool as IPool } from 'interfaces/Pools/Pool';
import TableLoading from 'components/common/TableLoading';
import pollServices from 'services/pool';
import useInfoWallet from 'hooks/useInfoWallet';
interface Props {
  pools: IPool[];
  currentPage: number;
  totalRecord: number;
  onChangePage: (newPage: number) => void;
  onOpenModalNotice: () => void;
  onClickApprove: () => void;
  setCurrentPool: (pool: IPool) => void;
  loading?: boolean;
}

const AllPool = (props: Props) => {
  const { account, chainId } = useInfoWallet();
  const {
    pools,
    currentPage,
    totalRecord,
    onChangePage,
    setCurrentPool,
    loading = false,
    onClickApprove,
    onOpenModalNotice,
  } = props;

  if (loading) {
    return <TableLoading />;
  }

  const isFirstStakePool = async (
    account: string | undefined | null,
    selectedPool: IPool
  ) => {
    if (!chainId || !account) return;

    try {
      const poolDetail = await pollServices.getPoolDetail(
        selectedPool.id,
        account
      );
      const myStakes = poolDetail.data.myStakes;
      return myStakes.length === 0;
    } catch (e) {
      return true;
    }
  };

  return pools && pools.length ? (
    <>
      {pools.map((item: any, index: number) => {
        return (
          <span key={index}>
            <Pool
              data={item}
              onClickStake={async () => {
                if (await isFirstStakePool(account, item)) {
                  onClickApprove();
                  setCurrentPool(item);
                } else {
                  onOpenModalNotice();
                  setCurrentPool(item);
                }
              }}
            />
          </span>
        );
      })}
      {totalRecord > ITEM_PER_PAGE && !!pools.length && (
        <Pagination
          total={totalRecord}
          onChange={onChangePage}
          current={currentPage}
          pageSize={ITEM_PER_PAGE}
          showSizeChanger={false}
        />
      )}
    </>
  ) : (
    <NoRecord />
  );
};

export default AllPool;
