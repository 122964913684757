import BigNumber from 'bignumber.js';
import { BalanceWithHover } from 'components/common/Text/BalanceWithHover';
import TooltipBase from 'components/common/Tooltip';
import { TokenPool, UserPool } from 'interfaces/Pools/Pool';
import moment from 'moment';
import { SUPPORTED_CHAIN_IDS } from 'utils/constants/chains';
import { useWindowSize } from 'hooks/useWindowSize';
import { BREAKPOINT } from 'utils/constants/breakpoint';
import DefaultToken from '../../../assets/images/icons/default_token.svg';
import useInfoWallet from 'hooks/useInfoWallet';
import { zip } from 'utils/function';

interface ClaimStakeProps {
  userPool: UserPool;
  lockPeriod: string;
  reward?: string[];
  poolChain: string;
  tokenPools: TokenPool[];
  onClickClaim: () => void;
}

const ClaimStake: React.FC<ClaimStakeProps> = (props: ClaimStakeProps) => {
  const { userPool, tokenPools, onClickClaim, reward, lockPeriod, poolChain } =
    props;
  const userTokenPools = userPool.userPoolTokens;
  const { chainId } = useInfoWallet();

  const nameChain =
    chainId === Number(process.env.REACT_APP_KCC_CHAIN_ID) ? 'KCC' : 'BSC';

  const incorrectChain =
    !SUPPORTED_CHAIN_IDS.includes(Number(chainId)) || poolChain !== nameChain;
  const width = useWindowSize();

  const statusReward = () => {
    if (reward) {
      for (let i = 0; i < reward?.length; i++) {
        if (new BigNumber(reward[i]).isGreaterThan(0)) {
          return true;
        }
      }
    } else {
      return false;
    }
  };

  const renderClaimButton = () => {
    const isInLockTime = BigNumber(
      BigNumber(userPool.startStake).plus(lockPeriod).toNumber()
    ).gt(moment().unix());
    if (incorrectChain) {
      return (
        <div
          style={{
            margin: '1px',
          }}
          className="claimStake"
        >
          {' '}
          <TooltipBase
            placement="bottom"
            title={`Wrong chain. Please switch to ${poolChain} to claim reward`}
            color="white"
            overlayClassName="pool-tooltip"
          >
            <button tabIndex={-1} className="fw-700 btn-claim disable">
              Claim
            </button>
          </TooltipBase>
        </div>
      );
    } else if (isInLockTime) {
      return (
        <div
          style={{
            margin: '1px',
          }}
          className="claimStake"
        >
          <TooltipBase
            placement="bottom"
            title={`You cannot claim before  ${moment
              .unix(
                new BigNumber(userPool.startStake).plus(lockPeriod).toNumber()
              )
              .format('HH:mm DD/MM/YYYY')}`}
            color="white"
            overlayClassName="pool-tooltip"
          >
            <button tabIndex={-1} className="fw-700 btn-claim disable">
              Claim
            </button>
          </TooltipBase>
        </div>
      );
    }

    const styles: React.CSSProperties = {};

    if (!reward || !statusReward()) {
      styles.margin = '1px';
    }

    return (
      <div style={{ ...styles }} className="claimStake">
        <button
          className={`fw-700 btn-claim ${
            !reward || !statusReward() ? 'disable' : null
          } `}
          onClick={onClickClaim}
          disabled={!reward || !statusReward() ? true : false}
        >
          Claim
        </button>
      </div>
    );
  };

  return (
    <div className="pool-claim">
      <div className="d-flex justify-between flex-wrap flex-align-center">
        {width > BREAKPOINT.MEDIUM ? (
          <div className="pool-claim-left d-flex">
            <div className="fw-400 period-until">
              <p className="fw-500 text text-title">Lock until</p>
              <div style={{ textAlign: 'left' }}>
                {lockPeriod === '0'
                  ? '-'
                  : moment
                      .unix(
                        new BigNumber(userPool.startStake)
                          .plus(lockPeriod)
                          .toNumber()
                      )
                      .format('MMM DD, YYYY HH:mm')}
              </div>
            </div>
            {userTokenPools.length
              ? userTokenPools.map((_: any, index: number) => (
                  <div className="fw-400" key={index}>
                    <p className="fw-500 text text-title">
                      {tokenPools[index].rewardToken.symbolTokenLp ? (
                        <div className="logo-img__icon-token-lp" key={index}>
                          <img
                            src={
                              tokenPools[index].rewardToken.token0?.largeLogo ||
                              DefaultToken
                            }
                            alt="logo"
                            className="logo-img__icon-token-lp__first"
                          />
                          <img
                            src={
                              tokenPools[index].rewardToken.token1?.largeLogo ||
                              DefaultToken
                            }
                            alt="logo"
                            className="logo-img__icon-token-lp__second"
                          />
                        </div>
                      ) : (
                        <img
                          className="logo-img"
                          src={
                            tokenPools[index].rewardToken.thumbLogo ||
                            DefaultToken
                          }
                          alt="logo"
                        />
                      )}
                      <span>
                        {tokenPools[index]?.rewardToken.symbolTokenLp
                          ? tokenPools[index]?.rewardToken.symbolTokenLp
                              .replaceAll('/', '-')
                              .concat(' LP')
                          : tokenPools[index]?.rewardToken.symbol}{' '}
                        reward
                      </span>
                    </p>
                    <div
                      style={{
                        width: 'fit-content',
                      }}
                    >
                      <BalanceWithHover
                        className="text-value text"
                        value={reward?.[index] ? reward?.[index] : '0'}
                        decimal={tokenPools[index].stakeToken.decimal}
                      />
                    </div>
                  </div>
                ))
              : null}
          </div>
        ) : (
          <div className="fw-500 text-title">My reward</div>
        )}
        <div className="helper-claim">{renderClaimButton()}</div>
      </div>
      {width <= BREAKPOINT.MEDIUM && (
        <div className="reward-mob-wrapper">
          <div className="fw-400 d-flex justify-between">
            <p className="fw-500 text text-title">Lock until</p>
            <div>
              {lockPeriod === '0'
                ? '-'
                : moment
                    .unix(
                      new BigNumber(userPool.startStake)
                        .plus(lockPeriod)
                        .toNumber()
                    )
                    .format('MMM DD, YYYY HH:mm')}
            </div>
          </div>
          {userTokenPools.length
            ? userTokenPools.map((_: any, index: number) => (
                <div className="fw-400 d-flex justify-between" key={index}>
                  <div className="fw-500 text-title">
                    {tokenPools[index].rewardToken.symbolTokenLp ? (
                      <div className="logo-img__icon-token-lp" key={index}>
                        <img
                          src={
                            tokenPools[index].rewardToken.token0?.largeLogo ||
                            DefaultToken
                          }
                          alt="logo"
                          className="logo-img__icon-token-lp__first"
                        />
                        <img
                          src={
                            tokenPools[index].rewardToken.token1?.largeLogo ||
                            DefaultToken
                          }
                          alt="logo"
                          className="logo-img__icon-token-lp__second"
                        />
                      </div>
                    ) : (
                      <img
                        className="logo-img"
                        src={
                          tokenPools[index].rewardToken.thumbLogo ||
                          DefaultToken
                        }
                        alt="logo"
                      />
                    )}
                    <span>
                      {tokenPools[index]?.rewardToken.symbolTokenLp
                        ? tokenPools[index]?.rewardToken.symbolTokenLp
                            .replaceAll('/', '-')
                            .concat(' LP')
                        : tokenPools[index]?.rewardToken.symbol}
                    </span>
                  </div>
                  <div
                    style={{
                      width: 'fit-content',
                    }}
                  >
                    <BalanceWithHover
                      className="text-value"
                      value={reward?.[index] ? reward?.[index] : '0'}
                      decimal={tokenPools[index].stakeToken.decimal}
                    />
                  </div>
                </div>
              ))
            : null}
        </div>
      )}
    </div>
  );
};

export default ClaimStake;
