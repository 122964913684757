import { Avatar, Col, Layout, Row, Space, Switch } from 'antd';
import ModalClaim from 'components/ModalClaim';
import { getContract } from 'helpers/getContract';
import useInfoWallet from 'hooks/useInfoWallet';
import { useWindowSize } from 'hooks/useWindowSize';
import { PoolStatusEnum } from 'interfaces';
import { Pool, TokenPool } from 'interfaces/Pools/Pool';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import { Token } from 'typescript';
import { BREAKPOINT } from 'utils/constants/breakpoint';
import { zip } from 'utils/function';
import DefaultToken from '../../../assets/images/icons/default_token.svg';
import '../../../styles/common.scss';
import { Stake } from './Stake';
import './style.scss';

const { Content } = Layout;

interface Props {
  onClickStake: (refreshFunc: () => void) => void;
  onClickUnstake: (refreshFunc: () => void) => void;
  data: Pool;
}

const getRewardIntervalTime = 10000;

export const PersonalPool = (props: Props) => {
  const { data, onClickStake, onClickUnstake } = props;
  const { tokenPools, status } = data || {};
  const [isOpenModalClaim, setIsOpenModalClaim] = useState(false);
  const { account, chainId } = useInfoWallet();
  const width = useWindowSize();

  const [isStake, setMode] = useState<boolean>(
    status === PoolStatusEnum.LIVE ? true : false
  );
  // caculator Reaward
  const [valueRewards, setValueRewards] = useState<string[]>([]);
  const [stakedAmounts, setStakedAmounts] = useState<string[]>([]);

  const isValidPeriod = (pool: Pool) => {
    const timeCreate = moment(`${pool.userPool?.startStake}`) // FIXME: this should be change to real staked time
      .add(pool.lockPeriod, 's')
      .unix();
    const day = moment().unix();
    return timeCreate - day > 0;
  };

  const getRewardAndMyStakedAmount = async (addressToken: string) => {
    const poolContract = getContract(data.chain, data.aprType, addressToken);
    let rewards: string[] = [];
    let stakedAmounts: string[] = [];
    try {
      if (account && poolContract) {
        if (data.aprType === 'DYNAMIC') {
          const response = await Promise.all([
            poolContract.methods.pendingToken(account).call(),
            poolContract.methods.getUserInfo(account).call(),
          ]);
          rewards = response[0];
          stakedAmounts = response[1].amount;
        } else {
          const response = await Promise.all([
            poolContract.methods.linearPendingReward(account).call(),
            poolContract.methods.linearBalanceOf(account).call(),
          ]);
          rewards = response[0];
          stakedAmounts = response[1];
        }
      }
      return {
        rewards,
        stakedAmounts,
      };
    } catch (error) {
      console.error(addressToken, error);
    }
  };

  const { data: response, mutate } = useSWR(
    [data.address],
    getRewardAndMyStakedAmount,
    {
      refreshInterval: getRewardIntervalTime,
    }
  );

  useEffect(() => {
    if (response) {
      setValueRewards(response.rewards);
      setStakedAmounts(response.stakedAmounts);
    }
  }, [response, chainId]);

  useEffect(() => {
    mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.address, chainId]);

  return (
    <Content
      className="site-layout-background"
      style={{
        marginTop: 10,
        padding: 20,
      }}
    >
      <Space>
        <Row className="title-wrapper">
          <Col className="pool-tokens pool-tokens-my-pool">
            <div className="logo-wrapper">
              {tokenPools
                ? zip<TokenPool, String>(
                    tokenPools,
                    data.stakeTokens.split('-')
                  ).map((tokenInfo, index) => {
                    const [token, tokenName] = tokenInfo;
                    const { largeLogo, token0, token1 } = token?.stakeToken;
                    const singleToken = (
                      <div className="logo-info" key={index}>
                        <img
                          src={largeLogo || DefaultToken}
                          alt="logo"
                          className="logo-info__icon-token-single"
                          key={index}
                        />
                        <div className="fw-700 titleTextPersonal">
                          {tokenName}
                        </div>
                      </div>
                    );
                    const lpToken = (
                      <div className="logo-info" key={index}>
                        <div className="logo-info__icon-token-lp" key={index}>
                          <img
                            src={token0?.largeLogo || DefaultToken}
                            alt="logo"
                            className="logo-info__icon-token-lp__first"
                          />
                          <img
                            src={token1?.largeLogo || DefaultToken}
                            alt="logo"
                            className="logo-info__icon-token-lp__second"
                          />
                        </div>
                        <div className="fw-700 titleTextPersonal">
                          {tokenName.replaceAll('/', '-')} LP
                        </div>
                      </div>
                    );
                    if (token0) return lpToken;
                    else return singleToken;
                  })
                : null}
            </div>
            <div className="label-switch-wrapper">
              <div className="label-wrapper">
                <span
                  className={`pool-status pool-${data.status.toLowerCase()} status-mobile`}
                >
                  {data.status.toLowerCase()}
                </span>
                <Col span={8}>
                  <div className={`fw-700 title create-date`}>
                    {moment
                      .unix(new Date(data.createdAt).getTime() / 1000)
                      .format('MM.DD')}
                  </div>
                  <div className={`fw-700 title ${data.chain}`}>
                    {data.chain}
                  </div>
                </Col>
              </div>
              {data.status === 'ENDED' || (
                <Switch
                  className={
                    isStake ? 'toggle-stake_stake' : 'toggle-stake_unstake'
                  }
                  checkedChildren={isStake ? 'Stake' : 'Unstake'}
                  unCheckedChildren={isStake ? 'Stake' : 'Unstake'}
                  defaultChecked={isStake}
                  onChange={setMode}
                />
              )}
            </div>
          </Col>
        </Row>
      </Space>
      <Stake
        onClickClaim={() => {
          setIsOpenModalClaim(true);
        }}
        onClickStake={() => {
          onClickStake(mutate);
        }}
        onClickUnstake={() => {
          onClickUnstake(mutate);
        }}
        pool={data}
        stakedAmounts={stakedAmounts}
        rewards={valueRewards}
        isPersonalPool={true}
        isStakeMode={isStake}
        isDisabled={isValidPeriod(data)}
      />
      {isOpenModalClaim && (
        <ModalClaim
          onClaimSuccessfully={mutate}
          isOpen={isOpenModalClaim}
          onOpen={() => {
            setIsOpenModalClaim(true);
          }}
          onClose={() => {
            setIsOpenModalClaim(false);
          }}
          selectedPool={data}
          rewards={valueRewards}
        />
      )}
    </Content>
  );
};
