import React from 'react';

import NoRecordIcon from '../../assets/images/icons/no-records.svg';

const NoRecord: React.FC = () => {
  return (
    <div className="no-record">
      <div>
        <img src={NoRecordIcon} alt="" />
        <p>No records</p>
      </div>
    </div>
  );
};

export default NoRecord;
