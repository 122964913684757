import './style.scss';
import { notification } from 'antd';
import iconFailed from 'assets/images/icons/approve-failed.svg';
import iconSuccess from 'assets/images/icons/approve-success.svg';
import iconLink from 'assets/images/icons/external-link.svg';
import BigNumber from 'bignumber.js';
import { getRPC } from 'helpers/getContract';
import useInfoWallet from 'hooks/useInfoWallet';
import { useCallback, useEffect, useState } from 'react';
import { MAX_UINT256, WALLET } from 'utils/constants';
import { CHAIN_INFO } from 'utils/constants/chains';
import StorageUtils from 'utils/storage';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils';
import { ConnectorNames } from 'utils/constants/connectors';
import ERC20_ABI from 'abi/ERC20.json';
import { useWeb3React } from '@web3-react/core';
import { TokenPool } from 'interfaces/Pools/Pool';
import ModalCustom from 'components/Modal';
import ButtonLG from 'components/common/ButtonLG';
import get from 'lodash/get';

interface ModalApproveProps {
  isOpen: boolean;
  onClose: () => void;
  onOpenModalStake: () => void;
  tokenPools: TokenPool[];
  addressPool: string;
}

const ModalApprove: React.FC<ModalApproveProps> = ({
  tokenPools,
  addressPool,
  isOpen,
  onClose,
  onOpenModalStake,
}) => {
  const { library } = useWeb3React();
  const { account, chainId } = useInfoWallet();
  const [isLoadingAllowance, setIsLoadingAllowance] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [targetPool, setTargetPool] = useState<undefined | TokenPool>();
  const wallet = StorageUtils.getItem(WALLET);
  const { astrone } = window as any;
  const web3 = new Web3(getRPC(Number(chainId)));

  const openNotification = (status: boolean, txh?: string) => {
    notification.open({
      message: undefined,
      description: (
        <div className={`toast-mess-approve-${status ? 'success' : 'failed'}`}>
          <div className="img-wrapper">
            <img src={status ? iconSuccess : iconFailed} alt="" />
          </div>
          <div className="description">
            {status ? (
              <>
                Approved successfully
                <div
                  className="link"
                  onClick={() => {
                    if (chainId && txh) {
                      window.open(
                        `${CHAIN_INFO[chainId].blockExplorerUrls}/tx/${txh}`,
                        '_blank'
                      );
                    }
                  }}
                >
                  <img src={iconLink} alt="" /> View on
                  {chainId === Number(process.env.REACT_APP_BSC_CHAIN_ID)
                    ? 'BCS'
                    : 'KCC'}{' '}
                  Scan
                </div>
              </>
            ) : (
              'Approve failed'
            )}
          </div>
        </div>
      ),
      duration: 5,
      top: 80,
    });
  };

  const checkApproved = useCallback(
    async (tokenPool: TokenPool) => {
      const addressToken = tokenPool.stakeToken.address;

      const tokenContract =
        wallet === ConnectorNames.Astrone
          ? new web3.eth.Contract(ERC20_ABI as AbiItem[], addressToken)
          : new library.eth.Contract(ERC20_ABI as AbiItem[], addressToken);

      const decimals = tokenPool.stakeToken.decimal as number;

      try {
        if (!!tokenContract && account) {
          setIsLoadingAllowance(true);
          const approveAmount = await tokenContract.methods
            .allowance(account, addressPool)
            .call();
          const allowanceAmountWithDecimal = new BigNumber(approveAmount).div(
            new BigNumber(10).pow(decimals)
          );
          setIsLoadingAllowance(false);
          return allowanceAmountWithDecimal.toString(10);
        }
        return '0';
      } catch (error) {
        console.error(error);
        setIsLoadingAllowance(false);
        return '0';
      }
    },
    [wallet, account, addressPool]
  );

  const handleApprove = async (addressToken: string, symbol: string) => {
    let tokenContract;
    if (wallet === ConnectorNames.Astrone) {
      setIsApproving(true);
      tokenContract = new web3.eth.Contract(
        ERC20_ABI as AbiItem[],
        addressToken
      );
      try {
        const estimateGas = await tokenContract.methods
          .approve(addressPool, MAX_UINT256)
          .estimateGas({
            from: account,
          });
        const gasPrice = await web3.eth.getGasPrice();
        const approveInfo = await astrone.request({
          method: 'eth_sendTransaction',
          data: {
            params: {
              from: account,
              to: addressToken,
              gas: estimateGas,
              gasPrice,
              value: '0x0',
              data: {
                spender: addressPool,
                amount: MAX_UINT256,
                tokenSymbol: symbol,
              },
            },
            type: 'approve',
          },
        });
        setIsApproving(false);
        openNotification(approveInfo.status, approveInfo.transactionHash);
        if (approveInfo.status) {
          onOpenModalStake();
          onClose();
        }
      } catch (error) {
        setIsApproving(false);
        openNotification(false);
        console.error('error', error);
      }
    } else {
      setIsApproving(true);
      tokenContract = new library.eth.Contract(
        ERC20_ABI as AbiItem[],
        addressToken
      );
      try {
        if (tokenContract && account) {
          const approveInfo = await tokenContract.methods
            .approve(addressPool, MAX_UINT256)
            .send({ from: account });
          setIsApproving(false);
          openNotification(
            approveInfo.status as boolean,
            approveInfo.transactionHash
          );
          onOpenModalStake();
          onClose();
        }
      } catch (error) {
        setIsApproving(false);
        openNotification(false);
        console.error(error);
      }
    }
  };

  useEffect(() => {
    if (tokenPools.length === 0) {
      return;
    }

    const targetPool = tokenPools.find(async (tokenPool) => {
      const result = await checkApproved(tokenPool);
      return result !== '0';
    });

    if (!targetPool) {
      onOpenModalStake();
      onClose();
    } else {
      setTargetPool(targetPool);
    }
  }, [tokenPools, checkApproved]);

  useEffect(() => {
    setIsApproving(false);
  }, [isOpen]);

  if (!targetPool) {
    return <></>;
  }

  return (
    <ModalCustom
      isOpen={isOpen}
      isShowCloseIcon={true}
      onClose={onClose}
      title="Approve"
      width="448px"
    >
      <div className="modal-approve">
        <div>You must approve token to stake.</div>
        <ButtonLG
          widthFull
          disabled={isApproving}
          onClick={() => {
            handleApprove(
              targetPool.stakeToken.address as string,
              get(
                targetPool,
                'stakeToken.symbolTokenLp',
                targetPool.stakeToken.symbol
              )
            );
          }}
        >
          {isApproving ? (
            'Approving...'
          ) : (
            <>
              Approve{' '}
              {targetPool.stakeToken.symbolTokenLp ||
                targetPool.stakeToken.symbol}
            </>
          )}
        </ButtonLG>
      </div>
    </ModalCustom>
  );
};

export default ModalApprove;
