import { PoolStatusEnum } from 'interfaces';
import { GetPoolResponse } from 'interfaces/Pools/GetPoolResponse';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import { TAB_NAME } from 'pages/HomePage';
import { useEffect, useMemo, useState } from 'react';
import pollServices from 'services/pool';
import { useAppDispatch } from 'store';
import { setRefreshPoolFunc } from 'store/reducers/commons';
import useSWR from 'swr';

export type ChainList = 'ALL' | 'BSC' | 'KCC' | '';

interface QueryParams {
  tokens: string;
  chain: ChainList;
  page: number;
  limit: number;
  status: PoolStatusEnum;
}

const intervalTime = 10000;

export const useFetchPools = (filters: QueryParams, tab: TAB_NAME) => {
  const [records, setRecords] = useState<GetPoolResponse>();
  const dispatch = useAppDispatch();
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const params = useMemo(() => {
    return pickBy(
      {
        ...filters,
      },
      identity
    );
  }, [filters]);

  const {
    data: response,
    error,
    mutate,
    isValidating,
  } = useSWR([params, !(tab === 'ALL')], pollServices.swrFetcherPool, {
    refreshInterval: intervalTime,
  });

  useEffect(() => {
    dispatch(setRefreshPoolFunc(mutate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (response) {
      setRecords({
        ...response.data.data,
      });
      setIsFirstLoad(false);
    }
  }, [response]);

  return {
    response: records,
    error,
    refresh: mutate,
    loading: isFirstLoad ? true : isValidating && !response,
  };
};
