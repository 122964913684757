import { useEagerConnect } from 'hooks/useEagerConnect';
import { useInactiveListener } from 'hooks/useInactiveListener';
import Homepage from 'pages/HomePage';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import Layout from './Layout';

const AppContainer = (props: any) => {
  // handle web3
  // try to eagerly connect to an injected provider, if it exists and has granted access already
  const triedEager = useEagerConnect();

  // when there's no account connected, react to logins (broadly speaking) on the injected provider, if it exists
  useInactiveListener(!triedEager);

  if (window.location.hostname === 'pepperfi.io') {
    window.location.replace('https://finance.astrone.io');
    return null;
  }

  return (
    <BrowserRouter>
      <Layout>
        <Switch>
          <Route exact path="/" component={Homepage} />
          {/* redirect to '/' */}
          <Route exact path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </Layout>
    </BrowserRouter>
  );
};

export default AppContainer;
