/* eslint-disable no-console */
import { useWeb3React } from '@web3-react/core';
import { WALLET } from 'utils/constants';
import { injectedConnector } from 'utils/constants/connectors';
import { useEffect } from 'react';
import StorageUtils from 'utils/storage';
import { useAstroneWallet } from 'contexts/AstroneContext';
import { ConnectorNames } from './../utils/constants/connectors';
/**
 * Use for network and injected - logs user in
 * and out after checking what network theyre on
 */
export function useInactiveListener(suppress = false) {
  const { active, error, activate, deactivate } = useWeb3React();
  const { account, loginAstrone, logoutAstrone } = useAstroneWallet();
  const walletStorage = StorageUtils.getItem(WALLET);

  useEffect(() => {
    const { ethereum, astrone } = window as any;
    if (
      ethereum &&
      ethereum.on &&
      !active &&
      !error &&
      !suppress &&
      walletStorage &&
      walletStorage !== ConnectorNames.Astrone
    ) {
      const handleChainChanged = (chainId: string | number) => {
        console.log("Handling 'chainChanged' event with payload", chainId);
        if (active) {
          activate(injectedConnector);
        } else {
          deactivate();
        }
      };
      const handleAccountsChanged = (accounts: string[]) => {
        console.log("Handling 'accountsChanged' event with payload", accounts);
        if (accounts.length > 0 && active) {
          activate(injectedConnector);
        }
        if (!accounts[0]) {
          deactivate();
          StorageUtils.setItem(WALLET, '');
        }
      };
      const handleNetworkChanged = (networkId: string | number) => {
        console.log("Handling 'networkChanged' event with payload", networkId);
        if (active) {
          activate(injectedConnector);
        } else {
          deactivate();
        }
      };

      ethereum.on('chainChanged', handleChainChanged);
      ethereum.on('accountsChanged', handleAccountsChanged);
      ethereum.on('networkChanged', handleNetworkChanged);

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener('chainChanged', handleChainChanged);
          ethereum.removeListener('accountsChanged', handleAccountsChanged);
          ethereum.removeListener('networkChanged', handleNetworkChanged);
        }
      };
    }

    if (
      astrone &&
      astrone.on &&
      account &&
      !suppress &&
      walletStorage &&
      walletStorage === ConnectorNames.Astrone
    ) {
      const handleChainChanged = (data: any) => {
        const walletStorage = StorageUtils.getItem(WALLET);
        if (walletStorage === ConnectorNames.Astrone) {
          console.log(
            "Handling 'chainChanged' event with payload",
            +data.chainId
          );
          if (!!data.chainId) {
            loginAstrone();
          } else {
            logoutAstrone();
          }
        }
      };
      const handleAccountsChanged = (data: any) => {
        const walletStorage = StorageUtils.getItem(WALLET);
        if (walletStorage === ConnectorNames.Astrone) {
          console.log(
            "Handling 'accountsChanged' event with payload",
            data.account
          );
          if (!!data.account) {
            loginAstrone();
          } else {
            logoutAstrone();
            window.location.reload();
          }
        }
      };

      astrone.on('chainChanged', handleChainChanged);
      astrone.on('accountsChanged', handleAccountsChanged);
    }

    return undefined;
  }, [
    active,
    error,
    suppress,
    activate,
    loginAstrone,
    logoutAstrone,
    deactivate,
    walletStorage,
    account,
  ]);
}
