import { useEffect, useState } from 'react';
import useInfoWallet from 'hooks/useInfoWallet';
import { notification } from 'antd';
import ModalSwitchNetwork from 'components/ModalSwitchNetwork';
import ModalConnectWallet from 'components/ModalConnectWallet';
import useCheckNetwork from 'hooks/useCheckNetwork';
import useAuth from 'hooks/useAuth';
import { useWindowSize } from 'hooks/useWindowSize';
import { BREAKPOINT } from 'utils/constants/breakpoint';
import { trimMiddlePartAddress } from 'helpers/trimMiddlePart';
import logoDesktop from 'assets/images/logo.svg';
import logoMobile from 'assets/images/logo-mobile.svg';
import iconWallet from 'assets/images/icons/wallet.svg';
import iconArrow from 'assets/images/icons/arrow.svg';
import iconWrong from 'assets/images/icons/wrong.svg';
import { useAstroneWallet } from 'contexts/AstroneContext';
import StorageUtils from 'utils/storage';
import { WALLET } from 'utils/constants';
import { ConnectorNames } from 'utils/constants/connectors';
import './styles/header.scss';
import { SupportedChainId } from 'utils/constants/chains';

const Header = () => {
  const { account, chainId } = useInfoWallet();
  const { loginAstrone } = useAstroneWallet();
  const [isOpenModalConnect, setIsOpenModalConnect] = useState(false);
  const [isOpenModalNetwork, setIsOpenModalNetwork] = useState(false);
  const isSupportedChain = useCheckNetwork();
  const { error } = useAuth();
  const width = useWindowSize();
  const wallet = StorageUtils.getItem(WALLET);

  const openNotification = () => {
    notification.open({
      message: undefined,
      description: (
        <div className="toast-err">
          <div className="img-wrong-wrapper">
            <img src={iconWrong} alt="" />
          </div>
          <div className="description">
            Network not supported. Please change to BSC or KCC network to
            continue
          </div>
        </div>
      ),
      duration: 7,
      top: 80,
    });
  };

  useEffect(() => {
    if (account && !!chainId && !isSupportedChain) {
      openNotification();
    }
  }, [isSupportedChain, account, chainId]);

  useEffect(() => {
    if (account && !error) {
      setIsOpenModalConnect(false);
    }
  }, [account, error]);

  useEffect(() => {
    if (wallet === ConnectorNames.Astrone) {
      setTimeout(() => {
        loginAstrone();
      }, 500);
    }
  }, []);

  return (
    <header className="header-wrapper">
      <div className="header">
        <div className="header__logo-wrapper">
          <img
            src={width > BREAKPOINT.SMALL ? logoDesktop : logoMobile}
            alt="logo"
            className="header__logo"
          />
          {width > BREAKPOINT.SMALL && <div className="beta-tag">Beta</div>}
        </div>
        {account && !!chainId && isSupportedChain && (
          <div className="connected-info">
            <div
              className={`connected-info__connected-network ${SupportedChainId[chainId]}`}
            >
              {SupportedChainId[chainId]}
            </div>
            <div
              className="connected-info__btn-info"
              onClick={() => {
                setIsOpenModalConnect(true);
              }}
            >
              <div className="helper connected">
                <img src={iconWallet} alt="" />
                <button
                  className="connected__btn"
                  tabIndex={1}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setIsOpenModalConnect(true);
                    }
                  }}
                >
                  {trimMiddlePartAddress(account)}
                </button>
                <img src={iconArrow} alt="" />
              </div>
            </div>
          </div>
        )}
        {!account && (
          <div
            className="connected-info__btn-info"
            onClick={() => {
              setIsOpenModalConnect(true);
            }}
          >
            <div className="helper">
              <button className="helper__connect-btn">
                Connect to a wallet
              </button>
            </div>
          </div>
        )}
        {account && !!chainId && !isSupportedChain && (
          <div
            className="header__btn-switch"
            onClick={() => {
              setIsOpenModalNetwork(true);
            }}
          >
            Change network
          </div>
        )}
        {isOpenModalConnect && (
          <ModalConnectWallet
            isOpen={isOpenModalConnect}
            onClose={() => {
              setIsOpenModalConnect(false);
            }}
          />
        )}
        {isOpenModalNetwork && (
          <ModalSwitchNetwork
            isOpen={isOpenModalNetwork}
            onClose={() => {
              setIsOpenModalNetwork(false);
            }}
          />
        )}
      </div>
    </header>
  );
};

export default Header;
