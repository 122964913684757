/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { AbiItem } from 'web3-utils';
import BigNumber from 'bignumber.js';
import InputStaking from 'components/InputStaking';
import ModalCustom from 'components/Modal';
import ButtonLG from 'components/common/ButtonLG';
import { useContract } from 'hooks/useContract';
import { checkSumInput } from 'helpers/sumInputArray';
import { MAX_DECIMAL, WALLET } from 'utils/constants';
import {
  formatNumberDivDecimals,
  numberWithCommas,
} from 'helpers/formatNumber';
import LINEAR_POOL_ABI from 'abi/LINEAR_POOL.json';
import ALLOCATION_POOL_ABI from 'abi/ALLOCATION_POOL.json';
import ModalProgressContent, {
  TypeAction,
  TypeProgress,
} from 'components/ModalProgress';
import { TokenPool } from 'interfaces/Pools/Pool';
import { getRPC } from 'helpers/getContract';
import StorageUtils from 'utils/storage';
import Web3 from 'web3';
import useInfoWallet from 'hooks/useInfoWallet';
import { ConnectorNames } from 'utils/constants/connectors';
import signatureServices from 'services/signature';
import './style.scss';

interface ModalUnstakeProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  tokenPools: TokenPool[];
  userPool: any;
  poolType: string;
  addressPool: string;
  poolId: number;
  isStaking: boolean;
  setIsStaking: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalUnstake: React.FC<ModalUnstakeProps> = ({
  isOpen,
  onOpen,
  onClose,
  tokenPools,
  poolType,
  addressPool,
  poolId,
  isStaking,
  setIsStaking,
}) => {
  const { account, chainId } = useInfoWallet();
  const numberTokens = tokenPools.length;
  const [initStateArray, setInitStateArray] = useState<string[]>([]);
  const rateArray = tokenPools.map((ele: any) => ele.rate as string);
  const decimalArray = tokenPools.map(
    (ele: any) => ele.stakeToken.decimal as number
  );
  const [messErr, setMessErr] = useState<string>();
  const [isInputChange, setIsInputChange] = useState(false);
  const [amountTokens, setAmountTokens] = useState<Array<string>>([]);
  const [amountTokensDisplay, setAmountTokensDisplay] = useState<Array<string>>(
    []
  );
  const [stakedAmounts, setStakedAmounts] = useState<Array<string>>([]);
  const [isConfirming, setIsConfirming] = useState(false);
  const [currentProgress, setCurrentProgress] = useState<TypeProgress>('');
  const [currentAction, setCurrentAction] = useState<TypeAction>('');
  const [txhash, setTxhash] = useState<string>('');
  const maxDecimalToken = decimalArray.find((item) => {
    return item < MAX_DECIMAL;
  });
  const maxDecimalPoll =
    maxDecimalToken !== undefined ? maxDecimalToken : MAX_DECIMAL;
  const wallet = StorageUtils.getItem(WALLET);
  const { astrone } = window as any;
  const web3 = new Web3(getRPC(Number(chainId)));

  useLayoutEffect(() => {
    const initArray = Array(numberTokens).fill('');
    setAmountTokens(initArray);
    setAmountTokensDisplay(initArray);
    setStakedAmounts(initArray);
    setInitStateArray(initArray);
    setTxhash('');
  }, [numberTokens]);

  const poolContract = useContract(
    poolType === 'FIXED'
      ? (LINEAR_POOL_ABI as AbiItem[])
      : (ALLOCATION_POOL_ABI as AbiItem[]),
    addressPool
  );

  const handleBack = () => {
    setIsConfirming(false);
  };

  const getMyStake = async () => {
    let poolContractSelected;
    if (wallet === ConnectorNames.Astrone) {
      const poolContractWeb3 = new web3.eth.Contract(
        poolType === 'FIXED'
          ? (LINEAR_POOL_ABI as AbiItem[])
          : (ALLOCATION_POOL_ABI as AbiItem[]),
        addressPool
      );
      poolContractSelected = poolContractWeb3;
    } else {
      if (poolContract) {
        poolContractSelected = poolContract;
      }
    }
    try {
      if (poolContractSelected && account) {
        let amountArray: string[];
        if (poolType === 'FIXED') {
          const linearBalanceOf = await poolContractSelected.methods
            .linearBalanceOf(account)
            .call();
          amountArray = linearBalanceOf as string[];
        } else {
          const allowcationBalanceOf = await poolContractSelected.methods
            .getUserInfo(account)
            .call();
          amountArray = allowcationBalanceOf.amount as string[];
        }

        const amountArrayDivDecimal = amountArray.map((item, index) => {
          const decimals = tokenPools[index].stakeToken.decimal as number;
          return new BigNumber(
            formatNumberDivDecimals(item, decimals)
          ).toString(10);
        });
        setStakedAmounts(amountArrayDivDecimal);
      }
    } catch (error) {
      return initStateArray;
    }
  };

  const handleChange = (value: string, index: number) => {
    if (isNaN(+value) || +value < 0) {
      return;
    }
    if (value) {
      const inputAmountTokens = amountTokens.map((_, i) => {
        const removeSpace = value.replace(/\s/g, '');
        if (index === i) {
          const parts = removeSpace.toString().split('.');
          if (!!parts[1] && parts[1].length >= maxDecimalPoll) {
            return new BigNumber(removeSpace).toFixed(maxDecimalPoll, 1);
          }
          return removeSpace;
        }
        if (!!removeSpace) {
          if (rateArray[index] === rateArray[i]) {
            return new BigNumber(
              new BigNumber(removeSpace).toFixed(maxDecimalPoll, 1)
            ).toString(10);
          }
          return new BigNumber(
            new BigNumber(new BigNumber(removeSpace).toFixed(maxDecimalPoll, 1))
              .div(new BigNumber(rateArray[index]))
              .times(new BigNumber(rateArray[i]))
              .toFixed(maxDecimalPoll, 1)
          ).toString(10);
        }
        return '';
      });
      setAmountTokens(inputAmountTokens);
      setAmountTokensDisplay(inputAmountTokens);
    } else {
      setAmountTokens(initStateArray);
      setAmountTokensDisplay(initStateArray);
    }
  };

  const handleClickMax = () => {
    setAmountTokens(stakedAmounts);
    setAmountTokensDisplay(stakedAmounts);
  };

  const setDisableBtn = useMemo(() => {
    const arrCheck = amountTokens.map((item, index) => {
      return new BigNumber(item).gt(new BigNumber(stakedAmounts[index]));
    });
    return arrCheck.some((ele) => ele);
  }, [amountTokens]);

  const handleUnstake = async () => {
    const amountTokensToWei = amountTokens.map((item, index) => {
      const decimals = tokenPools[index].stakeToken.decimal as number;
      return new BigNumber(item)
        .times(new BigNumber(10).pow(decimals))
        .toString(10);
    });
    if (wallet === ConnectorNames.Astrone) {
      setIsStaking(true);
      setCurrentAction('unstake');
      setCurrentProgress('progressing');
      const poolContractWeb3 = new web3.eth.Contract(
        poolType === 'FIXED'
          ? (LINEAR_POOL_ABI as AbiItem[])
          : (ALLOCATION_POOL_ABI as AbiItem[]),
        addressPool
      );
      try {
        let signature;
        if (poolType === 'DYNAMIC') {
          const signatureRes = await signatureServices.fetchSignature({
            userAddress: account,
            amounts: amountTokensToWei,
            poolId,
          });
          signature = signatureRes.data.data.signature;
        }
        const dataEncodeABI =
          poolType === 'FIXED'
            ? await poolContractWeb3.methods
                .linearWithdraw(amountTokensToWei)
                .encodeABI()
            : await poolContractWeb3.methods
                .withdraw(amountTokensToWei, signature)
                .encodeABI();
        const estimateGas =
          poolType === 'FIXED'
            ? await poolContractWeb3.methods
                .linearWithdraw(amountTokensToWei)
                .estimateGas({
                  from: account,
                })
            : await poolContractWeb3.methods
                .withdraw(amountTokensToWei, signature)
                .estimateGas({
                  from: account,
                });
        const gasPrice = await web3.eth.getGasPrice();
        const nonce = await web3.eth.getTransactionCount(account as string);
        const unstakeInfo = await astrone.request({
          method: 'eth_sendTransaction',
          data: {
            params: {
              from: account,
              to: addressPool,
              gas: estimateGas,
              gasPrice,
              value: '0x0',
              data: dataEncodeABI,
              nonce,
            },
          },
        });
        if (unstakeInfo.status) {
          setCurrentProgress('successfully');
          setTxhash(unstakeInfo.transactionHash);
        } else {
          setCurrentProgress('failed');
          setIsConfirming(false);
        }
      } catch (error) {
        setCurrentProgress('failed');
        setIsConfirming(false);
        onOpen();
        console.error(error);
      }
    } else {
      if (poolContract) {
        try {
          setIsStaking(true);
          setCurrentAction('unstake');
          setCurrentProgress('progressing');

          let unstakeInfo;
          if (poolType === 'FIXED') {
            unstakeInfo = await poolContract.methods
              .linearWithdraw(amountTokensToWei)
              .send({ from: account });
          } else {
            const signature = await signatureServices.fetchSignature({
              userAddress: account,
              amounts: amountTokensToWei,
              poolId,
            });
            unstakeInfo = await poolContract.methods
              .withdraw(amountTokensToWei, signature.data.data.signature)
              .send({ from: account });
          }
          if (unstakeInfo.status) {
            setCurrentProgress('successfully');
            setTxhash(unstakeInfo.transactionHash);
          } else {
            setCurrentProgress('failed');
            setIsConfirming(false);
          }
        } catch (error) {
          setCurrentProgress('failed');
          setIsConfirming(false);
          onOpen();
          console.error(error);
        }
      }
    }
  };

  useEffect(() => {
    if (isInputChange) {
      if (amountTokens.every((ele) => ele === '')) {
        setMessErr(
          `Please fill in ${tokenPools.length === 1 ? 'this' : 'these'} field${
            tokenPools.length === 1 ? '' : 's'
          }`
        );
      } else {
        setMessErr('');
      }
    } else {
      setMessErr('');
    }
  }, [amountTokens]);

  useEffect(() => {
    getMyStake();
  }, [tokenPools, isOpen, chainId]);

  useEffect(() => {
    if (!isStaking) {
      setIsConfirming(false);
    }
  }, [isStaking, tokenPools]);

  useEffect(() => {
    setAmountTokens(initStateArray);
    setAmountTokensDisplay(initStateArray);
    setIsInputChange(false);
    setIsConfirming(false);
  }, [isOpen, initStateArray]);

  useEffect(() => {
    if (isStaking && !!txhash) {
      onOpen();
    }
  }, [txhash]);

  return (
    <ModalCustom
      isOpen={isOpen}
      onClickBackIcon={handleBack}
      isShowBackIcon={isConfirming && !isStaking}
      isShowCloseIcon={true}
      onClose={onClose}
      title={isStaking ? undefined : 'Unstake'}
      width="448px"
    >
      {isStaking ? (
        <ModalProgressContent
          onClose={onClose}
          currentProgress={currentProgress}
          action={currentAction}
          txhash={txhash}
        />
      ) : (
        <div className="modal-unstake">
          {isConfirming ? (
            <>
              <div className="token-info">
                <div className="token">
                  <div>Unstake amount:</div>
                  {amountTokensDisplay.map((item: string, index: number) => {
                    return (
                      <div key={index} className="text-token">
                        {numberWithCommas(item)}{' '}
                        {tokenPools[index].stakeToken.symbolTokenLp ||
                          tokenPools[index].stakeToken.symbol}
                      </div>
                    );
                  })}
                </div>
              </div>
              <ButtonLG widthFull onClick={handleUnstake}>
                Confirm
              </ButtonLG>
            </>
          ) : (
            <>
              {numberTokens && numberTokens > 1 && (
                <div className="modal-unstake__notice">
                  <div>
                    *Unstaking ratio between tokens
                    {tokenPools.map((item: TokenPool, index: number) => {
                      return (
                        <span key={index}>
                          : {item.rate}{' '}
                          {item.stakeToken.symbolTokenLp ||
                            item.stakeToken.symbol}{' '}
                        </span>
                      );
                    })}
                  </div>
                </div>
              )}
              <div className="modal-stake__input-wrapper">
                {tokenPools.map((item: any, index: number) => {
                  return (
                    <InputStaking
                      key={item.id}
                      iconToken={item.stakeToken.thumbLogo}
                      stakeToken={item.stakeToken}
                      nameToken={
                        item.stakeToken.symbolTokenLp || item.stakeToken.symbol
                      }
                      type="unstake"
                      value={amountTokensDisplay[index]}
                      onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        handleChange(e.currentTarget.value as string, index);
                        setIsInputChange(true);
                      }}
                      amount={stakedAmounts[index]}
                      onClickMax={() => {
                        handleClickMax();
                      }}
                    />
                  );
                })}
                {messErr && !setDisableBtn && (
                  <div className="error">* {messErr}</div>
                )}
              </div>
              <div className="modal-stake__btn-group">
                <button className="btn-cancel" onClick={onClose}>
                  Cancel
                </button>
                <ButtonLG
                  widthFull
                  onClick={() => {
                    setIsConfirming(true);
                  }}
                  disabled={
                    checkSumInput(amountTokens) === 0 ||
                    !!messErr ||
                    setDisableBtn ||
                    amountTokens.some((ele) => +ele === 0)
                  }
                >
                  {checkSumInput(amountTokens) === 0
                    ? 'Enter an amount'
                    : 'Unstake'}
                </ButtonLG>
              </div>
            </>
          )}
        </div>
      )}
    </ModalCustom>
  );
};

export default ModalUnstake;
