import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ModalCustom from 'components/Modal';
import ButtonLG from 'components/common/ButtonLG';
import Spinner from 'components/common/Spinner';
import useAuth from 'hooks/useAuth';
import { useSwitchNetwork } from 'hooks/useSwitchNetwork';
import { ConnectorNames } from 'utils/constants/connectors';
import { WALLET } from 'utils/constants';
import { CHAIN_INFO } from 'utils/constants/chains';
import { trimMiddlePartAddress } from 'helpers/trimMiddlePart';
import StorageUtils from 'utils/storage';
import iconLink from 'assets/images/icons/link.svg';
import iconCopy from 'assets/images/icons/copy.svg';
import iconSuccess from 'assets/images/icons/success.svg';
import iconAstrone from 'assets/images/icons/astrone.svg';
import iconMetamask from 'assets/images/icons/metamask.svg';
import iconWalletconnect from 'assets/images/icons/walletconnect.svg';
import useInfoWallet from 'hooks/useInfoWallet';
import useCheckNetwork from 'hooks/useCheckNetwork';
import './style.scss';

interface ModalConnectWalletProps {
  isOpen: boolean;
  onClose: () => void;
}
const ModalConnectWallet: React.FC<ModalConnectWalletProps> = ({
  isOpen,
  onClose,
}) => {
  const { account, chainId } = useInfoWallet();

  const { login, logout, isLoading, error } = useAuth();
  const { trySwitchNetworks } = useSwitchNetwork();
  const isSupportedChain = useCheckNetwork();
  const wallet = StorageUtils.getItem(WALLET);

  const [copied, setCopied] = useState(false);

  const handleLogin = async (connectorId: ConnectorNames) => {
    await login(connectorId);
  };

  const copyAccountToClipBoard = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <ModalCustom
      isOpen={isOpen}
      isShowCloseIcon={true}
      onClose={onClose}
      title={account ? 'Account' : 'Select a Wallet'}
    >
      {account ? (
        <div className="modal-info">
          <div className="modal-info__address">
            <img
              className="modal-info__address__logo-wallet"
              src={
                wallet === 'injected'
                  ? iconMetamask
                  : wallet === 'walletconnect'
                  ? iconWalletconnect
                  : iconAstrone
              }
              alt=""
            />
            <div className="modal-info__address__details">
              <div>
                Connected with{' '}
                {wallet === 'injected'
                  ? 'MetaMask'
                  : wallet === 'walletconnect'
                  ? 'WalletConnect'
                  : 'Astrone'}
              </div>
              <div>
                {trimMiddlePartAddress(account)}
                <CopyToClipboard
                  text={account}
                  onCopy={() => {
                    copyAccountToClipBoard();
                  }}
                >
                  <img src={copied ? iconSuccess : iconCopy} alt="" />
                </CopyToClipboard>
                {copied && <div>Copied</div>}
              </div>
            </div>
          </div>
          <div className="modal-info__clickable">
            <div className="btn-wrapper">
              <button
                className="btn-disconnect"
                onClick={() => {
                  logout();
                  onClose();
                }}
              >
                Disconnect
              </button>
              <ButtonLG
                widthFull
                onClick={() => {
                  trySwitchNetworks(
                    chainId === Number(process.env.REACT_APP_KCC_CHAIN_ID)
                      ? Number(process.env.REACT_APP_BSC_CHAIN_ID)
                      : Number(process.env.REACT_APP_KCC_CHAIN_ID)
                  );
                  onClose();
                }}
              >
                Switch
              </ButtonLG>
            </div>
            <a
              className="explorer"
              href={
                chainId && isSupportedChain
                  ? `${CHAIN_INFO[chainId].blockExplorerUrls}/address/${account}`
                  : '/'
              }
              target={'_blank'}
              rel="noreferrer"
            >
              <img src={iconLink} alt="" />
              <div>View on explorer</div>
            </a>
          </div>
        </div>
      ) : (
        <div className="modal-connect">
          {isLoading && (
            <div className="modal-connect__loading">
              <Spinner />
              <div>Connecting...</div>
            </div>
          )}
          {error && <div className="modal-connect__error">{error}</div>}
          <button
            className="modal-connect__item"
            onClick={() => {
              handleLogin(ConnectorNames.Astrone);
            }}
          >
            <img src={iconAstrone} alt="" />
            <div>Astrone wallet</div>
          </button>
          <button
            className="modal-connect__item"
            onClick={() => {
              handleLogin(ConnectorNames.Injected);
            }}
          >
            <img src={iconMetamask} alt="" />
            <div>MetaMask</div>
          </button>
          <button
            className="modal-connect__item"
            onClick={() => {
              handleLogin(ConnectorNames.WalletConnect);
            }}
          >
            <img src={iconWalletconnect} alt="" />
            <div>WalletConnect</div>
          </button>
        </div>
      )}
    </ModalCustom>
  );
};

export default ModalConnectWallet;
