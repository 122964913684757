import Web3 from 'web3';
import ALLOCATION_POOL_ABI from 'abi/ALLOCATION_POOL.json';
import LINEAR_POOL_ABI from 'abi/LINEAR_POOL.json';
import { AbiItem } from 'web3-utils';

export const getContract = (
  poolChain: string,
  aprType: string,
  addressToken: string
) => {
  const rpcUrl =
    poolChain === 'BSC'
      ? String(process.env.REACT_APP_BSC_RPC_URL)
      : String(process.env.REACT_APP_KCC_RPC_URL);
  const web3 = new Web3(rpcUrl);
  return new web3.eth.Contract(
    aprType === 'DYNAMIC'
      ? (ALLOCATION_POOL_ABI as AbiItem[])
      : (LINEAR_POOL_ABI as AbiItem[]),
    addressToken
  );
};

export const getRPC = (chainId: number) => {
  return Number(chainId) === Number(process.env.REACT_APP_BSC_CHAIN_ID)
    ? String(process.env.REACT_APP_BSC_RPC_URL)
    : String(process.env.REACT_APP_KCC_RPC_URL);
};
