import { createContext, useContext, useState } from 'react';
import { WALLET } from 'utils/constants';
import StorageUtils from 'utils/storage';

interface AstroneProviderProps {
  children: React.ReactNode;
}

interface ContextValue {
  account?: string | null;
  chainId?: number;
  loginAstrone: () => Promise<any>;
  logoutAstrone: () => void;
}

const AstroneContext = createContext<ContextValue | undefined>(undefined);

const AstroneProvider = ({ children }: AstroneProviderProps): JSX.Element => {
  const [account, setAccount] = useState<string | null>();
  const [chainId, setChainId] = useState<number>();

  const loginAstrone = async () => {
    try {
      const infoAccount = await (window as any).astrone.request({
        method: 'eth_accounts',
      });
      if (infoAccount.status !== 'success') {
        throw false;
      } else {
        setAccount(infoAccount.account);
      }
      const infoNetwork = await (window as any).astrone.request({
        method: 'eth_getNetwork',
      });
      setChainId(Number(infoNetwork.chainId));
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const logoutAstrone = () => {
    StorageUtils.setItem(WALLET, '');
    setAccount(undefined);
    setChainId(undefined);
  };

  const value = { account, chainId, loginAstrone, logoutAstrone };
  return (
    <AstroneContext.Provider value={value}>{children}</AstroneContext.Provider>
  );
};

const useAstroneWallet = (): ContextValue => {
  const context = useContext(AstroneContext);
  if (context === undefined) {
    throw new Error('useAstroneWallet must be within a AstroneContext');
  }
  return context;
};

export { AstroneProvider, useAstroneWallet };
