import { useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import iconDefault from 'assets/images/icons/default-token.svg';
import { truncateNumber } from 'helpers/truncateNumber';
import DefaultToken from '../../assets/images/icons/default_token.svg';
import './style.scss';
import { Token } from 'interfaces/Pools/Token';
import { numberWithCommas, shortNumber } from 'helpers/formatNumber';

interface InputStakingProps {
  iconToken: string;
  nameToken: string;
  type: 'stake' | 'unstake';
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  amount: string;
  onClickMax: () => void;
  isDisableInput?: boolean;
  stakeToken: Token;
}

const InputStaking: React.FC<InputStakingProps> = ({
  iconToken,
  nameToken,
  type,
  onChange,
  value,
  amount,
  onClickMax,
  isDisableInput,
  stakeToken,
}) => {
  const { largeLogo, token0, token1 } = stakeToken;
  const [initValue] = useState<string>('');
  const [messErr, setMessErr] = useState<string>();
  useEffect(() => {
    if (new BigNumber(value).gt(new BigNumber(amount))) {
      if (type === 'stake') {
        setMessErr('Insufficient balance');
      } else {
        setMessErr('Insufficient staked amount');
      }
    } else {
      setMessErr('');
    }
  }, [value, amount, type]);

  const singleToken = <img src={largeLogo || iconDefault} alt="" />;
  const lpToken = (
    <div className="name__icon-token-lp">
      <img
        src={token0?.largeLogo || DefaultToken}
        alt="logo"
        className="name__icon-token-lp__first"
      />
      <img
        src={token1?.largeLogo || DefaultToken}
        alt="logo"
        className="name__icon-token-lp__second"
      />
    </div>
  );
  return (
    <div className="input-staking-wrapper">
      <div className="token-info-with-input">
        <div className="name">
          {token0 ? lpToken : singleToken}
          <div>{nameToken}</div>
        </div>
        <div
          className="amount"
          title={numberWithCommas(
            new BigNumber(truncateNumber(amount, 6, false)).toString(10)
          )}
        >
          <>
            {type === 'stake' ? 'Balance' : 'My stake'}:{' '}
            {shortNumber(numberWithCommas(truncateNumber(amount, 6, false))) ||
              '0'}
          </>
        </div>
      </div>
      <div className="input-wrapper">
        <input
          value={value || initValue}
          type="text"
          placeholder="0"
          onChange={onChange}
          disabled={isDisableInput}
          onPaste={(e) => {
            const str = e.clipboardData.getData('Text');
            if (isNaN(+str) || str.includes('0x')) {
              e.preventDefault();
            }
          }}
        />
        <button disabled={+amount === 0 || isDisableInput} onClick={onClickMax}>
          Max
        </button>
      </div>
      {messErr && <div className="mess-err">* {messErr}</div>}
    </div>
  );
};

export default InputStaking;
