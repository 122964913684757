import './style.scss';

const Spinner: React.FC = () => {
  return (
    <div className="spinner">
      <span className="loader-wheel__spoke" />
      <span className="loader-wheel__spoke" />
      <span className="loader-wheel__spoke" />
      <span className="loader-wheel__spoke" />
      <span className="loader-wheel__spoke" />
      <span className="loader-wheel__spoke" />
      <span className="loader-wheel__spoke" />
      <span className="loader-wheel__spoke" />
    </div>
  );
};

export default Spinner;
