import useInfoWallet from 'hooks/useInfoWallet';
import Spinner from 'components/common/Spinner';
import { CHAIN_INFO } from 'utils/constants/chains';
import iconSuccessfully from 'assets/images/icons/successfully.svg';
import iconFailed from 'assets/images/icons/failed.svg';
import iconLink from 'assets/images/icons/link.svg';
import './style.scss';

export type TypeProgress = 'progressing' | 'successfully' | 'failed' | '';
export type TypeAction = 'stake' | 'unstake' | 'claim' | '';

interface ModalProgressContentProps {
  onClose: () => void;
  currentProgress: TypeProgress;
  action: TypeAction;
  txhash?: string;
}

const ModalProgressContent: React.FC<ModalProgressContentProps> = ({
  onClose,
  currentProgress,
  action,
  txhash,
}) => {
  const { chainId } = useInfoWallet();

  return (
    <div className="modal-progress">
      {currentProgress === 'progressing' && (
        <>
          <Spinner />
          <div className="progress">Progressing...</div>
          <div className="action">
            {action === 'stake' ? 'Staking' : null}
            {action === 'unstake' ? 'Unstaking' : null}
            {action === 'claim' ? 'Claiming' : null}
          </div>
        </>
      )}
      {currentProgress === 'successfully' && (
        <>
          <img src={iconSuccessfully} alt="" />
          <div className="progress">
            {action === 'stake' ? 'Staked' : null}
            {action === 'unstake' ? 'Unstaked' : null}
            {action === 'claim' ? 'Claimed' : null} successfully
          </div>
          <div
            className="link"
            onClick={() => {
              if (chainId) {
                window.open(
                  `${CHAIN_INFO[chainId].blockExplorerUrls}/tx/${txhash}`,
                  '_blank'
                );
              }
            }}
          >
            <div>View transaction details</div>
            <img src={iconLink} alt="" />
          </div>
        </>
      )}
      {currentProgress === 'failed' && (
        <>
          <img src={iconFailed} alt="" />
          <div className="progress">Failed</div>
          <div className="dismiss" onClick={onClose}>
            Dismiss
          </div>
        </>
      )}
    </div>
  );
};

export default ModalProgressContent;
