import useInfoWallet from 'hooks/useInfoWallet';
import { useMemo } from 'react';
import { SupportedChainId, SUPPORTED_CHAIN_IDS } from 'utils/constants/chains';

export default function useCheckNetwork() {
  const { chainId } = useInfoWallet();

  return useMemo(() => {
    return (
      chainId !== null &&
      SUPPORTED_CHAIN_IDS.includes(chainId as SupportedChainId)
    );
  }, [chainId]);
}
