import iconArrow from 'assets/images/icons/arrow-down.svg';
import iconFilter from 'assets/images/icons/filter.svg';
import iconSearch from 'assets/images/icons/search.svg';
import iconClear from 'assets/images/icons/clear-input.svg';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useWindowSize } from 'hooks/useWindowSize';
import { BREAKPOINT } from 'utils/constants/breakpoint';
import { CustomCheckBox } from 'components/common/CheckBox/CustomCheckBox';
import './style.scss';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { CustomDropdown } from 'components/common/CustomDropdown';

interface FilterBarProps {
  onSearch: (keyword: string) => void;
  onFilter: (args: any) => void;
  chainName: string;
}

const ListCheckBox = [
  { label: 'ALL', id: 1 },
  { label: 'BSC', id: 2 },
  { label: 'KCC', id: 3 },
];

interface ArrowIconProps {
  open: boolean;
}
const ArrowIcon = ({ open }: ArrowIconProps) => {
  return (
    <img
      style={{
        transform: open ? 'rotate(180deg)' : 'unset',
      }}
      src={iconArrow}
      alt=""
    />
  );
};

const FilterBar: React.FC<FilterBarProps> = (props: FilterBarProps) => {
  const { onSearch, onFilter, chainName } = props;
  const [selectedChainIds, setSelectedIds] = useState([1, 2, 3]);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [openInputMobile, setOpenInputMobile] = useState(false);
  const [inputSearch, setInputSearch] = useState('');

  const ref = useRef();

  useOnClickOutside(ref, () => setOpenInputMobile(false));

  const width = useWindowSize();

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    onSearch(event.target.value);
    setInputSearch(event.target.value);
  };

  const handleChangeCheckBox = (chain: any, checked: Boolean) => {
    if (chain.label === 'ALL') {
      if (checked) {
        setSelectedIds([1, 2, 3]);
        onFilter('ALL');
      } else {
        setSelectedIds([]);
        onFilter('');
      }
    } else {
      if (checked) {
        selectedChainIds.push(chain.id);
        if (selectedChainIds.length === ListCheckBox.length - 1) {
          setSelectedIds([1, 2, 3]);
          onFilter('ALL');
        } else {
          setSelectedIds([...selectedChainIds]);
          onFilter(chain.label); // Work with case two options without ALL
        }
      } else {
        const rest = selectedChainIds.filter((i) => i !== chain.id && i !== 1);
        if (rest.length) {
          let chainSelected = ListCheckBox.filter((j) => j.id === rest[0]);
          onFilter(chainSelected[0].label);
        } else {
          onFilter('');
        }

        setSelectedIds(rest);
      }
    }
  };

  const DropdownContent = () => {
    return (
      <div className="dropdown-wrapper">
        {ListCheckBox.map((chain) => {
          return (
            <CustomCheckBox
              key={chain.id}
              checked={selectedChainIds.includes(chain.id)}
              onChange={(checked) => {
                handleChangeCheckBox(chain, checked);
              }}
            >
              {chain.label}
            </CustomCheckBox>
          );
        })}
      </div>
    );
  };

  useEffect(() => {
    const elements = document.getElementsByClassName('content-wrapper');
    const func = () => {
      setOpenDropdown(false);
    };
    if (elements.length > 0) {
      elements[0].addEventListener('scroll', func);
    }
    return () => {
      if (elements.length > 0) {
        elements[0].removeEventListener('scroll', func);
      }
    };
  }, []);

  useEffect(() => {
    if (chainName === 'ALL') {
      setSelectedIds([1, 2, 3]);
    } else {
      setSelectedIds(
        ListCheckBox.filter((el) => el.label === chainName).map((el) => el.id)
      );
    }
  }, [chainName]);

  return (
    <div className="filter-bar-wrapper">
      <div className="filter-bar">
        {width > BREAKPOINT.SMALL && (
          <div className="heading">{width > BREAKPOINT.MEDIUM && 'Pools'}</div>
        )}
        <div className="input-wrapper">
          {openInputMobile ? (
            <div
              className="input-search-wrapper"
              ref={ref as unknown as React.LegacyRef<HTMLDivElement>}
            >
              <input
                value={inputSearch}
                type="text"
                placeholder="Search"
                onChange={handleInputChange}
              />
              <img src={iconSearch} alt="" className="icon-search" />
              <img
                src={iconClear}
                alt=""
                className="icon-clear"
                onClick={() => {
                  setInputSearch('');
                  onSearch('');
                }}
              />
            </div>
          ) : (
            <>
              {width > BREAKPOINT.SMALL ? (
                <div className="input-search-wrapper">
                  <input
                    type="text"
                    placeholder="Search"
                    onChange={handleInputChange}
                  />
                  <img src={iconSearch} alt="" className="icon-search" />
                </div>
              ) : (
                <div
                  className="btn-search"
                  onClick={() => {
                    setOpenInputMobile(true);
                  }}
                >
                  <img src={iconSearch} alt="" />
                </div>
              )}
              <CustomDropdown
                open={openDropdown}
                DropdownComponent={<DropdownContent />}
                onOpenChange={(e) => {
                  setOpenDropdown(e);
                }}
              >
                <button className="trigger-checkbox">
                  <img src={iconFilter} alt="" />
                  <div>Chain</div>
                  <ArrowIcon open={openDropdown} />
                </button>
              </CustomDropdown>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default FilterBar;
