import checkedIc from 'assets/images/icons/checked-ic.svg';
import uncheckedIc from 'assets/images/icons/unchecked-ic.svg';
import React from 'react';
import './style.scss';

interface Props {
  checked: boolean;
  onChange: (checked: boolean) => void;
  children: React.ReactNode;
  checkedIcon?: string;
  uncheckedIcon?: string;
}

export const CustomCheckBox = ({
  checked,
  onChange,
  children,
  checkedIcon,
  uncheckedIcon,
}: Props) => {
  return (
    <button
      className="d-flex custom-checkbox"
      type="button"
      onClick={() => {
        onChange(!checked);
      }}
    >
      <img
        alt="check-box-ic"
        src={!checked ? uncheckedIcon || uncheckedIc : checkedIcon || checkedIc}
      />
      <div>{children}</div>
    </button>
  );
};
