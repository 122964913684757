import { useAstroneWallet } from 'contexts/AstroneContext';
import { ConnectorNames } from 'utils/constants/connectors';
import { useWeb3React } from '@web3-react/core';
import { CHAIN_INFO } from 'utils/constants/chains';
import { useCallback, useState } from 'react';
import { WALLET } from 'utils/constants';
import StorageUtils from 'utils/storage';

export const useSwitchNetwork = () => {
  const wallet = StorageUtils.getItem(WALLET);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { astrone } = window as any;
  const { library } = useWeb3React();
  const { loginAstrone } = useAstroneWallet();

  const trySwitchNetworks = useCallback(
    async (chainId: number) => {
      // Check if MetaMask is installed
      // MetaMask injects the global API into window.ethereum
      const chainInfo = CHAIN_INFO[chainId];
      const params = {
        chainId: chainInfo.hexChainId,
        chainName: chainInfo.label,
        nativeCurrency: {
          name: chainInfo.nativeCurrency.name,
          symbol: chainInfo.nativeCurrency.symbol,
          decimals: chainInfo.nativeCurrency.decimals,
        },
        rpcUrls: [chainInfo.rpcUrl],
        blockExplorerUrls: [chainInfo.blockExplorerUrls],
      };
      if (
        wallet === ConnectorNames.WalletConnect ||
        wallet === ConnectorNames.Injected
      ) {
        if (library && library._provider && library._provider.request) {
          try {
            setIsLoading(true);
            await library._provider.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: chainInfo.hexChainId }],
            });
          } catch (error: any) {
            if (error.message.includes('Unrecognized chain ID')) {
              try {
                await library._provider.request({
                  method: 'wallet_addEthereumChain',
                  params: [params],
                });
              } catch (addError) {
                // eslint-disable-next-line no-console
                console.error(addError);
              }
            }
          } finally {
            setIsLoading(false);
          }
        } else {
          // if no window.ethereum then MetaMask is not installed
          alert(
            'MetaMask is not installed. Please consider installing it: https://metamask.io/download.html'
          );
        }
      } else if (wallet === ConnectorNames.Astrone) {
        try {
          setIsLoading(true);
          await astrone.request({
            method: 'eth_changeNetwork',
            data: {
              params: {
                chainId: chainInfo.chainId,
              },
            },
          });
          loginAstrone();
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      } else {
        alert('Please connect a wallet');
      }
    },
    [library, astrone, wallet]
  );

  return { trySwitchNetworks, isLoading };
};
