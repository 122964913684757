import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { KeyedMutator } from 'swr';

interface States {
  refreshPool?: () => void;
  refreshMyPool?: () => void;
}

const initialState: States = {};

export const commonSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setRefreshPoolFunc: (
      state,
      action: PayloadAction<KeyedMutator<AxiosResponse<any, any> | undefined>>
    ) => {
      state.refreshPool = action.payload;
    },
    setRefreshMyPoolFunc: (
      state,
      action: PayloadAction<KeyedMutator<AxiosResponse<any, any> | undefined>>
    ) => {
      state.refreshMyPool = action.payload;
    },
  },
});

export const { setRefreshPoolFunc, setRefreshMyPoolFunc } = commonSlice.actions;

const { reducer: commonReducer } = commonSlice;

export default commonReducer;
