import { useState, useEffect, useRef } from 'react';
import { Tabs } from 'antd';
import ModalStake from 'components/ModalStake/indexV2';
import ModalUnstake from 'components/ModalUnstake';
import FilterBar from 'components/FilterBar';
import MyPool from './MyPool';
import AllPool from './AllPool';
import useDebounce from 'hooks/useDebounce';
import { ITEM_PER_PAGE } from 'utils/constants';
import { useLocation, useHistory } from 'react-router-dom';
import { ChainList, useFetchPools } from 'hooks/useFetchPools';
import { PoolStatusEnum } from 'interfaces';
import get from 'lodash/get';
import { useFetchMyPool } from 'hooks/useFetchMyPools';
import { Pool } from 'interfaces/Pools/Pool';
import useInfoWallet from 'hooks/useInfoWallet';
import './style.scss';
import '../../styles/_variable.scss';
import ModalApprove from 'components/ModalApprove';
import { SupportedChainId } from 'utils/constants/chains';
import ModalNotice from 'components/ModalNotice';
import { getContract } from 'helpers/getContract';

type TabPosition = 'top';

export type TAB_NAME = 'ALL' | 'USER';

const Homepage: React.FC = () => {
  const { account, chainId } = useInfoWallet();
  const [tabPosition] = useState<TabPosition>('top');
  const [currentPage, setCurrentPage] = useState(1);
  const [isStaking, setIsStaking] = useState(false);
  const [filterChain, setChainFilter] = useState<ChainList>('ALL');
  const [keyword, setKeyword] = useState('');
  const [isOpenModalStake, setIsOpenModalStake] = useState(false);
  const [isOpenModalUnstake, setIsOpenModalUnstake] = useState(false);
  const [isOpenModalApprove, setIsOpenModalApprove] = useState(false);
  const [isOpenModalNotice, setIsOpenModalNotice] = useState(false);
  const [currentPool, setCurrentPool] = useState<Pool>({
    lockPeriod: '',
    tokenPools: [],
    stakingLimit: '',
    totalTokenPools: [],
    aprType: '',
    address: '',
    userPool: {},
  } as unknown as Pool);
  const { search, pathname } = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(search);
  const currentTab = ['USER', 'ALL'].includes(String(query.get('tab')))
    ? (query.get('tab') as TAB_NAME)
    : 'ALL';
  const initTab = currentTab === 'USER' ? '2' : '1';
  const [tab, setTab] = useState<TAB_NAME>(currentTab);
  const [filteredPools, setFilteredPools] = useState<Pool[]>([]);
  const debouncedValue = useDebounce<string>(keyword, 500);
  const emptyFunc = () => {};
  const selectedPoolRefreshFunc = useRef(emptyFunc);
  const handleFilterBar = (chain: string) => {
    setChainFilter(chain as ChainList);
    setCurrentPage(1);
  };

  const handleSearchToken = (keyword: string) => {
    setCurrentPage(1);
    setKeyword(keyword);
  };

  const handleChangePage = (page: number) => {
    setCurrentPage(page);
  };

  const { response: poolResponse, loading: poolLoading } = useFetchPools(
    {
      page: currentPage,
      limit: ITEM_PER_PAGE,
      status: PoolStatusEnum.LIVE,
      chain: filterChain,
      tokens: debouncedValue,
    },
    tab
  );

  const { response: myPoolResponse, loading: myPoolLoading } = useFetchMyPool(
    {
      page: currentPage,
      limit: ITEM_PER_PAGE,
      status: PoolStatusEnum.LIVE,
      chain: filterChain,
      tokens: debouncedValue,
    },
    tab,
    account
  );

  useEffect(() => {
    const getFilteredPools = async () => {
      const pools = get(poolResponse, 'result', []);
      const poolsWithContract = await Promise.all(
        pools.map(async (pool) => {
          const poolContract = getContract(
            pool.chain,
            pool.aprType,
            pool.address
          );
          return {
            pool,
            isEnded: await poolContract?.methods?.isEnd()?.call(),
          };
        })
      );

      const filteredPools = poolsWithContract
        .filter((pool) => !pool.isEnded)
        .map((pool) => pool.pool);
      setFilteredPools(filteredPools);
    };
    getFilteredPools();
  }, [poolResponse]);

  useEffect(() => {
    setIsOpenModalStake(false);
    setIsOpenModalUnstake(false);
    setChainFilter(chainId ? (SupportedChainId[chainId] as ChainList) : 'ALL');
  }, [account, chainId]);
  /**
   * Set default tab list
   */
  const tabItem = [
    {
      Label: 'All Pools',
      Component: (
        <AllPool
          onClickApprove={() => {
            setIsOpenModalApprove(true);
            setIsStaking(false);
          }}
          setCurrentPool={setCurrentPool}
          pools={filterChain === '' ? [] : filteredPools}
          currentPage={currentPage}
          totalRecord={get(poolResponse, 'total', 0)}
          onChangePage={handleChangePage}
          onOpenModalNotice={() => setIsOpenModalNotice(true)}
          loading={poolLoading}
        />
      ),
    },
    {
      Label: 'My Pools',
      Component: (
        <MyPool
          onClickStake={(refreshPoolFunc: () => void) => {
            setIsOpenModalStake(true);
            setIsStaking(false);
            selectedPoolRefreshFunc.current = refreshPoolFunc;
          }}
          onClickUnstake={(refreshPoolFunc: () => void) => {
            setIsOpenModalUnstake(true);
            setIsStaking(false);
            selectedPoolRefreshFunc.current = refreshPoolFunc;
          }}
          onOpenModalNotice={() => setIsOpenModalNotice(true)}
          setCurrentPool={setCurrentPool}
          pools={filterChain === '' ? [] : myPoolResponse?.result}
          currentPage={currentPage}
          totalRecord={get(myPoolResponse, 'total', 0)}
          onChangePage={handleChangePage}
          isConnected={!!account}
          loading={!!account && myPoolLoading}
          chainId={chainId}
        />
      ),
    },
  ];

  return (
    <div className="container">
      <FilterBar
        onSearch={handleSearchToken}
        onFilter={handleFilterBar}
        chainName={filterChain}
      />
      <div className="containerTab">
        <div className="tab-content">
          <Tabs
            defaultActiveKey={initTab}
            className="pool-tabs"
            onChange={(i) => {
              const tab = +i === 1 ? 'ALL' : 'USER';
              setTab(tab);
              setCurrentPage(1);

              history.push(pathname + '?tab=' + tab);
            }}
            tabPosition={tabPosition}
            items={tabItem.map((_, i) => {
              const id = String(i + 1);
              return {
                label: _.Label,
                key: id,
                children: (
                  <>
                    <div key={id}>{_.Component}</div>
                  </>
                ),
              };
            })}
          />
        </div>
      </div>
      {account && (
        <>
          <ModalApprove
            isOpen={isOpenModalApprove}
            onClose={() => {
              setIsOpenModalApprove(false);
              selectedPoolRefreshFunc.current();
            }}
            onOpenModalStake={() => {
              setIsOpenModalStake(true);
            }}
            tokenPools={currentPool.tokenPools}
            addressPool={currentPool.address}
          />
          <ModalNotice
            isOpen={isOpenModalNotice}
            onClose={() => setIsOpenModalNotice(false)}
            onStake={() => {
              setIsOpenModalStake(true);
              setIsStaking(false);
            }}
          />
          <ModalStake
            isOpen={isOpenModalStake}
            onOpen={() => {
              setIsOpenModalStake(true);
            }}
            onClose={() => {
              setIsOpenModalStake(false);
              selectedPoolRefreshFunc.current();
            }}
            lockPeriod={currentPool.lockPeriod}
            tokenPools={currentPool.tokenPools}
            stakingLimit={currentPool.stakingLimit}
            totalTokenPools={currentPool.totalTokenPools}
            poolType={currentPool.aprType}
            addressPool={currentPool.address}
            poolId={currentPool.id}
            isStaking={isStaking}
            setIsStaking={setIsStaking}
          />
          {!!currentPool.userPool &&
            Object.keys(currentPool.userPool).length > 0 && (
              <ModalUnstake
                isOpen={isOpenModalUnstake}
                onOpen={() => {
                  setIsOpenModalUnstake(true);
                }}
                onClose={() => {
                  setIsOpenModalUnstake(false);
                  selectedPoolRefreshFunc.current();
                }}
                userPool={currentPool.userPool}
                tokenPools={currentPool.tokenPools}
                poolType={currentPool.aprType}
                addressPool={currentPool.address}
                poolId={currentPool.id}
                isStaking={isStaking}
                setIsStaking={setIsStaking}
              />
            )}
        </>
      )}
    </div>
  );
};

export default Homepage;
