import { numberWithCommas } from 'helpers/formatNumber';
import BigNumber from 'bignumber.js';
import reverse from 'lodash/reverse';
const millionLength = 7;
const billionLength = 10;
const trillionLength = 13;
const convertNumber = (value: string, type: 'M' | 'B' | 'T') => {
  const formatValue = numberWithCommas(new BigNumber(value).toString(10));
  const commaCount = formatValue.match(/,/g);
  if (type === 'T' && commaCount && commaCount.length >= 5) {
    const numbers = formatValue.split(',');
    const items = [];
    let count = 0;
    for (let i = numbers.length; i >= 0; i--) {
      if (count >= 5) {
        items.push(numbers[i]);
      }
      count += 1;
    }
    const afterDecimal = reverse(items).join(',');
    return afterDecimal + type;
  }
  const numbers = formatValue.split(',');

  const isZeroNumbers =
    numbers[1].slice(0, 2) === '0' ||
    numbers[1].slice(0, 2) === '00' ||
    numbers[1].slice(0, 2) === '00';

  const afterDecimal = numbers[1]
    ? !isZeroNumbers
      ? '.' + numbers[1].slice(0, 2)
      : ''
    : '';
  return numbers[0] + afterDecimal + type;
};

export const formatLargeNumber = (value: string) => {
  const removeCommaVal = value.replaceAll(',', '');
  if (value.includes('<')) return value;
  const [leftNumbers] = removeCommaVal.split('.');
  if (leftNumbers.length >= trillionLength) {
    return convertNumber(leftNumbers, 'T');
  } else if (leftNumbers.length >= billionLength) {
    return convertNumber(leftNumbers, 'B');
  } else if (leftNumbers.length >= millionLength) {
    return convertNumber(leftNumbers, 'M');
  }
};
