import { useWeb3React } from '@web3-react/core';
import { AbiItem } from 'web3-utils';
import { Contract } from 'web3-eth-contract';
import Web3 from 'web3';

export const useContract = (
  ABI: AbiItem[],
  address: string
): Contract | null => {
  const { library } = useWeb3React<Web3>();
  if (!library || !ABI || !address) {
    return null;
  } else {
    const contract = new library.eth.Contract(ABI, address);
    return contract;
  }
};
