import { Tooltip } from 'antd';
import BigNumber from 'bignumber.js';
import { formatLargeNumber } from 'helpers/formatLargeNumber';
import { numberWithCommas } from 'helpers/formatNumber';
import { truncateNumber } from 'helpers/truncateNumber';
import { useEffect, useState } from 'react';
import { MAX_DECIMAL } from 'utils/constants';

interface Props {
  value: string;
  decimal: number;
  text?: string;
  symbol?: string;
  className?: string;
  addSpace?: boolean;
  enableHover?: boolean;
}

export const BalanceWithHover = ({
  value,
  decimal,
  className,
  symbol,
  text = '',
  addSpace = true,
  enableHover = true,
}: Props) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const elements = document.getElementsByClassName('content-wrapper');
    const func = () => {
      setOpen(false);
    };
    if (elements.length > 0) {
      elements[0].addEventListener('scroll', func);
    }
    return () => {
      if (elements.length > 0) {
        elements[0].removeEventListener('scroll', func);
      }
    };
  }, []);

  const handleFormatNumber = (
    value: string,
    afterDecimalLength = MAX_DECIMAL
  ) => {
    const amount = truncateNumber(
      BigNumber(value).div(new BigNumber(10).pow(decimal)).toString(),
      afterDecimalLength,
      false
    );

    return formatLargeNumber(amount) || numberWithCommas(amount);
  };
  return (
    <Tooltip
      placement="bottom"
      open={open}
      title={numberWithCommas(
        BigNumber(value)
          .div(Number(`1e${decimal}`))
          .toString(10)
      )}
      color="white"
      overlayClassName="pool-tooltip"
      onOpenChange={() => {
        if (
          !BigNumber(
            handleFormatNumber(value, MAX_DECIMAL).replaceAll(',', '')
          ).eq(
            BigNumber(value)
              .div(Number(`1e${decimal}`).toFixed(MAX_DECIMAL))
              .toString(10)
          ) &&
          enableHover
        ) {
          setOpen(!open);
        }
      }}
    >
      <div className={className}>{`${handleFormatNumber(value)}${
        symbol ? (addSpace ? ' ' : '') + symbol : ''
      }${text}`}</div>
    </Tooltip>
  );
};
