// CONFIG NETWORK CHAIN FOR DAPP
import iconKCC from 'assets/images/icons/kcc.svg';
import iconBSC from 'assets/images/icons/bsc.svg';

export enum SupportedChainId {
  BSC = Number(process.env.REACT_APP_BSC_CHAIN_ID),
  KCC = Number(process.env.REACT_APP_KCC_CHAIN_ID),
}

export const SUPPORTED_CHAIN_IDS: SupportedChainId[] = [
  SupportedChainId.BSC,
  SupportedChainId.KCC,
];

export const CHAIN_INFO = {
  [SupportedChainId.BSC]: {
    label: 'Binance Smart Chain',
    icon: iconBSC,
    hexChainId: process.env.REACT_APP_BSC_HEX_CHAIN_ID,
    chainId: process.env.REACT_APP_BSC_CHAIN_ID,
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrl: process.env.REACT_APP_BSC_RPC_URL,
    blockExplorerUrls: process.env.REACT_APP_BSC_SCAN_URL,
  },
  [SupportedChainId.KCC]: {
    label: 'KuCoin Community Chain',
    icon: iconKCC,
    hexChainId: process.env.REACT_APP_KCC_HEX_CHAIN_ID,
    chainId: process.env.REACT_APP_KCC_CHAIN_ID,
    nativeCurrency: {
      name: 'KCS',
      symbol: 'KCS',
      decimals: 18,
    },
    rpcUrl: process.env.REACT_APP_KCC_RPC_URL,
    blockExplorerUrls: process.env.REACT_APP_KCC_SCAN_URL,
  },
};
