import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { useEffect, useRef, useState } from 'react';
import './style.scss';

interface Props {
  children: React.ReactNode;
  DropdownComponent: React.ReactNode;
  open: boolean;
  onOpenChange: (value: boolean) => void;
}

export const CustomDropdown = ({
  children,
  DropdownComponent,
  open,
  onOpenChange,
}: Props) => {
  const [width, setWidth] = useState(0);
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current) {
      const width = (ref.current as any).offsetWidth;
      setWidth(width);
    }
  }, [ref.current]);

  useOnClickOutside(ref, () => {
    onOpenChange(false);
  });

  return (
    <div
      ref={ref}
      onClick={() => {
        onOpenChange(true);
      }}
      className="dropdown-container"
    >
      <>{children}</>
      <div
        style={{
          width,
          display: open ? 'block' : 'none',
        }}
        className="wrapper slowlyScale"
      >
        {DropdownComponent}
      </div>
    </div>
  );
};
