import BigNumber from 'bignumber.js';

export const truncateNumber = (
  value: string,
  decimal: number,
  replaceComma = true
) => {
  if (BigNumber(value).lt(1 / 10 ** decimal) && !BigNumber(value).eq(0))
    return (
      '<' + new BigNumber(1).div(new BigNumber(10).pow(decimal).toString(10))
    );
  if (BigNumber(value).eq(0) || BigNumber(value).toString() === 'NaN')
    return '0';

  if (replaceComma) {
    return new BigNumber(
      new BigNumber(value).toFixed(decimal, BigNumber.ROUND_DOWN)
    )
      .toString(10)
      .replaceAll(',', '')
      .replace(/\.0+$/, '');
  }
  return new BigNumber(value)
    .toFixed(decimal, BigNumber.ROUND_DOWN)
    .replace(/\.0+$/, '');
};
