import React from 'react';

import DisconnectIcon from '../../assets/images/icons/try-connect.svg';

const Disconnected: React.FC = () => {
  return (
    <div className="disconnect-frame">
      <div>
        <img src={DisconnectIcon} alt="" />
        <p>Please connect wallet to view your pools</p>
      </div>
    </div>
  );
};

export default Disconnected;
