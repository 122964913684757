import { ButtonProps } from 'antd/lib/button';
import './style.scss';

interface ButtonLGProps extends ButtonProps {
  widthFull?: boolean;
}
const ButtonLG: React.FC<ButtonLGProps> = ({
  children,
  widthFull,
  onClick,
  disabled,
}) => {
  return (
    <button
      className={`btn-linear-gradient ${widthFull ? 'w-full' : ''}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default ButtonLG;
