export const formatNumber = (number: number, factor: number) => {
  if (!number) return 0;
  if (number <= 0.01) return number;

  let base = 10 ** factor;
  let result: any = Math.round(number * base) / base;

  if (result >= 1000000 && result < 1000000000) {
    result = `${(result / 1000000).toFixed(factor)} M`;
  }

  if (result > 1000000000 && result < 1000000000000) {
    result = `${(result / 1000000000).toFixed(factor)} B`;
  }

  if (result > 1000000000000) {
    result = `${(result / 1000000000000).toFixed(factor)} T`;
  }

  return result;
};

export const formatDay = (time: any) => {
  let result = time / 60;
  if (time === 0 || result === 0) {
    return time;
  }
  if (time > 0) {
    result = time / (24 * 3600);
    return result
      .toFixed(3)
      .toString()
      .replace(/(\.0+|0+)$/, '');
  }
};

export const zip = <U, T>(array1: U[], array2: T[]): [U, T][] => {
  const arrays = [array1, array2];
  const minLength = arrays.reduce(
    (acc, cur) => Math.min(acc, cur.length),
    Infinity
  );
  return Array.from({ length: minLength }, (_, i) => [array1[i], array2[i]]);
};
