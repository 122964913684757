import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './styles/common.scss';
import devConsole from 'utils/devConsole';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    release: `astrone-finance_${process.env.npm_package_version}`,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing(), new Sentry.Replay()],
    // TODO:
    // tracesSampleRate: For development purposes you can keep it at 1.0.
    // For production it is better to decrease this to 0.1
    // (meaning 10% of your user's will report performance analytics)
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

devConsole.init({
  name: process.env.REACT_APP_NAME ?? 'NAME',
  version: process.env.REACT_APP_VERSION ?? 'VERSION',
  mode: process.env.NODE_ENV,
  env: process.env,
  style: { color: '#4ae6e6' },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
