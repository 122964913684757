import { Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';
import React, { useEffect, useState } from 'react';

interface TProps {
  title: string;
  placement?: TooltipPlacement;
  color?: string;
  overlayStyle?: object;
  overlayClassName?: string;
  children?: React.ReactNode;
}
const TooltipBase = (props: TProps) => {
  const {
    title,
    placement = 'bottom',
    color = '#100929',
    overlayStyle,
    overlayClassName,
    children,
    ...rest
  } = props;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const elements = document.getElementsByClassName('content-wrapper');
    const func = () => {
      setOpen(false);
    };
    if (elements.length > 0) {
      elements[0].addEventListener('scroll', func);
    }
    return () => {
      if (elements.length > 0) {
        elements[0].removeEventListener('scroll', func);
      }
    };
  }, []);

  return (
    <Tooltip
      onOpenChange={() => {
        setOpen(!open);
      }}
      open={open}
      title={title}
      placement={placement}
      overlayStyle={overlayStyle}
      overlayClassName={overlayClassName}
      color={color}
      {...rest}
    >
      {children}
    </Tooltip>
  );
};
export default TooltipBase;
