import { ServiceBase } from 'request/service-base';

class SignatureServices extends ServiceBase {
  fetchSignature = async (params: any) => {
    return await this.post(
      `${process.env.REACT_APP_API_BASE_URL}/signature`,
      params
    );
  };
}

const signatureServices = new SignatureServices();

export default signatureServices;
