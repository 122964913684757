/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { AbiItem } from 'web3-utils';
import { useWeb3React } from '@web3-react/core';
import BigNumber from 'bignumber.js';
import InputStaking from 'components/InputStaking';
import ModalCustom from 'components/Modal';
import ButtonLG from 'components/common/ButtonLG';
import { useContract } from 'hooks/useContract';
import ERC20_ABI from 'abi/ERC20.json';
import LINEAR_POOL_ABI from 'abi/LINEAR_POOL.json';
import ALLOCATION_POOL_ABI from 'abi/ALLOCATION_POOL.json';
import { checkSumInput } from 'helpers/sumInputArray';
import ModalProgressContent, {
  TypeAction,
  TypeProgress,
} from 'components/ModalProgress';
import {
  formatNumberDivDecimals,
  numberWithCommas,
} from 'helpers/formatNumber';
import { formatDay } from 'utils/function';
import { MAX_DECIMAL, WALLET } from 'utils/constants';
import { truncateNumber } from 'helpers/truncateNumber';
import { TokenPool } from 'interfaces/Pools/Pool';
import StorageUtils from 'utils/storage';
import { ConnectorNames } from 'utils/constants/connectors';
import Web3 from 'web3';
import { getRPC } from 'helpers/getContract';
import useInfoWallet from 'hooks/useInfoWallet';
import signatureServices from 'services/signature';
import './style.scss';

interface ModalStakeProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  lockPeriod: string;
  tokenPools: TokenPool[];
  totalTokenPools: any;
  stakingLimit: string;
  poolType: string;
  addressPool: string;
  poolId?: number;
  isStaking: boolean;
  setIsStaking: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalStake: React.FC<ModalStakeProps> = ({
  isOpen,
  onOpen,
  onClose,
  lockPeriod,
  tokenPools,
  totalTokenPools,
  stakingLimit,
  poolType,
  addressPool,
  poolId,
  isStaking,
  setIsStaking,
}) => {
  const { library } = useWeb3React();
  const { account, chainId } = useInfoWallet();
  const numberTokens = tokenPools.length;
  const rateArray = tokenPools.map((ele: any) => ele.rate as string);
  const decimalArray = tokenPools.map(
    (ele: any) => ele.stakeToken.decimal as number
  );
  const [messErr, setMessErr] = useState<string>();
  const [isInputChange, setIsInputChange] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isLoadingAllowance, setIsLoadingAllowance] = useState(false);
  const [isDisableInput, setIsDisableInput] = useState(false);
  const [initStateArray, setInitStateArray] = useState<string[]>([]);
  const [amountTokens, setAmountTokens] = useState<Array<string>>([]);
  const [amountTokensDisplay, setAmountTokensDisplay] = useState<Array<string>>(
    []
  );
  const [balanceAmounts, setBalanceAmounts] = useState<Array<string>>([]);
  const [currentProgress, setCurrentProgress] = useState<TypeProgress>('');
  const [currentAction, setCurrentAction] = useState<TypeAction>('');
  const [txhash, setTxhash] = useState<string>('');
  const maxDecimalToken = decimalArray.find((item) => {
    return item < MAX_DECIMAL;
  });
  const maxDecimalPoll =
    maxDecimalToken !== undefined ? maxDecimalToken : MAX_DECIMAL;
  const wallet = StorageUtils.getItem(WALLET);
  const { astrone } = window as any;
  const web3 = new Web3(getRPC(Number(chainId)));

  useLayoutEffect(() => {
    const initArray = Array(numberTokens).fill('');
    setAmountTokens(initArray);
    setAmountTokensDisplay(initArray);
    setBalanceAmounts(initArray);
    setInitStateArray(initArray);
    setTxhash('');
  }, [numberTokens]);

  const poolContract = useContract(
    poolType === 'FIXED'
      ? (LINEAR_POOL_ABI as AbiItem[])
      : (ALLOCATION_POOL_ABI as AbiItem[]),
    addressPool
  );

  const getBalanceToken = async (addressToken: string, index: number) => {
    const tokenContract =
      wallet === ConnectorNames.Astrone
        ? new web3.eth.Contract(ERC20_ABI as AbiItem[], addressToken)
        : new library.eth.Contract(ERC20_ABI as AbiItem[], addressToken);

    const decimals = tokenPools[index].stakeToken.decimal as number;
    try {
      if (tokenContract && account) {
        setIsLoadingAllowance(true);
        const balanceAmount = await tokenContract.methods
          .balanceOf(account)
          .call();
        const balanceAmountWithDecimal = new BigNumber(balanceAmount).div(
          new BigNumber(10).pow(decimals)
        );
        setIsLoadingAllowance(false);
        return balanceAmountWithDecimal.toString(10);
      }
    } catch (error) {
      setIsLoadingAllowance(true);
      console.error(error);
    }
  };

  const handleChange = (value: string, index: number) => {
    if (isNaN(+value) || +value < 0) {
      return;
    }
    if (value) {
      const inputAmountTokens = amountTokens.map((_, i) => {
        const removeSpace = value.replace(/\s/g, '');
        if (index === i) {
          const parts = removeSpace.toString().split('.');
          if (!!parts[1] && parts[1].length >= maxDecimalPoll) {
            return new BigNumber(removeSpace).toFixed(maxDecimalPoll, 1);
          }
          return removeSpace;
        }
        if (!!removeSpace) {
          if (rateArray[index] === rateArray[i]) {
            return new BigNumber(
              new BigNumber(removeSpace).toFixed(maxDecimalPoll, 1)
            ).toString(10);
          }
          return new BigNumber(
            new BigNumber(new BigNumber(removeSpace).toFixed(maxDecimalPoll, 1))
              .div(new BigNumber(rateArray[index]))
              .times(new BigNumber(rateArray[i]))
              .toFixed(maxDecimalPoll, 1)
          ).toString(10);
        }
        return '';
      });
      setAmountTokens(inputAmountTokens);
      setAmountTokensDisplay(inputAmountTokens);
    } else {
      setAmountTokens(initStateArray);
      setAmountTokensDisplay(initStateArray);
    }
  };

  const handleClickMax = (value: string, index: number) => {
    const inputAmountTokens = amountTokens.map((_, i) => {
      if (index === i) {
        return new BigNumber(
          new BigNumber(value).toFixed(maxDecimalPoll, 1)
        ).toString(10);
      }
      return new BigNumber(
        new BigNumber(value)
          .div(new BigNumber(rateArray[index]))
          .times(new BigNumber(rateArray[i]))
          .toFixed(maxDecimalPoll, 1)
      ).toString(10);
    });

    const inputAmountTokensDisplay = amountTokens.map((_, i) => {
      if (index === i) {
        return new BigNumber(
          new BigNumber(value).toFixed(maxDecimalPoll, 1)
        ).toString(10);
      }
      return new BigNumber(
        new BigNumber(value)
          .div(new BigNumber(rateArray[index]))
          .times(new BigNumber(rateArray[i]))
          .toFixed(maxDecimalPoll, 1)
      ).toString(10);
    });
    setAmountTokens(inputAmountTokens);
    setAmountTokensDisplay(inputAmountTokensDisplay);
  };

  const setDisableBtn = useMemo(() => {
    const arrCheck = amountTokens.map((item, index) => {
      return new BigNumber(item).gt(new BigNumber(balanceAmounts[index]));
    });
    return arrCheck.some((ele) => ele);
  }, [amountTokens]);

  const handleBack = () => {
    setIsConfirming(false);
  };

  const handleStake = async () => {
    const amountTokensToWei = amountTokens.map((item, index) => {
      const decimals = tokenPools[index].stakeToken.decimal as number;
      return new BigNumber(item)
        .times(new BigNumber(10).pow(decimals))
        .toString(10);
    });

    if (wallet === ConnectorNames.Astrone) {
      setIsStaking(true);
      setCurrentAction('stake');
      setCurrentProgress('progressing');
      const poolContractWeb3 = new web3.eth.Contract(
        poolType === 'FIXED'
          ? (LINEAR_POOL_ABI as AbiItem[])
          : (ALLOCATION_POOL_ABI as AbiItem[]),
        addressPool
      );
      try {
        let signature;
        if (poolType === 'DYNAMIC') {
          const signatureRes = await signatureServices.fetchSignature({
            userAddress: account,
            amounts: amountTokensToWei,
            poolId,
          });
          signature = signatureRes.data.data.signature;
        }
        const dataEncodeABI =
          poolType === 'FIXED'
            ? await poolContractWeb3.methods
                .linearDeposit(amountTokensToWei)
                .encodeABI()
            : await poolContractWeb3.methods
                .deposit(amountTokensToWei, signature)
                .encodeABI();
        const estimateGas =
          poolType === 'FIXED'
            ? await poolContractWeb3.methods
                .linearDeposit(amountTokensToWei)
                .estimateGas({
                  from: account,
                })
            : await poolContractWeb3.methods
                .deposit(amountTokensToWei, signature)
                .estimateGas({
                  from: account,
                });
        const gasPrice = await web3.eth.getGasPrice();
        const nonce = await web3.eth.getTransactionCount(account as string);
        const stakeInfo = await astrone.request({
          method: 'eth_sendTransaction',
          data: {
            params: {
              from: account,
              to: addressPool,
              gas: estimateGas,
              gasPrice,
              value: '0x0',
              data: dataEncodeABI,
              nonce,
            },
          },
        });
        if (stakeInfo.status) {
          setCurrentProgress('successfully');
          setTxhash(stakeInfo.transactionHash);
        } else {
          setCurrentProgress('failed');
          setIsConfirming(false);
        }
      } catch (error) {
        setCurrentProgress('failed');
        setIsConfirming(false);
        onOpen();
        console.error(error);
      }
    } else {
      if (poolContract) {
        try {
          setIsStaking(true);
          setCurrentAction('stake');
          setCurrentProgress('progressing');

          let stakeInfo;
          if (poolType === 'FIXED') {
            stakeInfo = await poolContract.methods
              .linearDeposit(amountTokensToWei)
              .send({ from: account });
          } else {
            const signature = await signatureServices.fetchSignature({
              userAddress: account,
              amounts: amountTokensToWei,
              poolId,
            });
            stakeInfo = await poolContract.methods
              .deposit(amountTokensToWei, signature.data.data.signature)
              .send({ from: account });
          }
          if (stakeInfo.status) {
            setCurrentProgress('successfully');
            setTxhash(stakeInfo.transactionHash);
          } else {
            setCurrentProgress('failed');
            setIsConfirming(false);
          }
        } catch (error) {
          setCurrentProgress('failed');
          setIsConfirming(false);
          onOpen();
          console.error(error);
        }
      }
    }
  };

  useEffect(() => {
    if (isInputChange && amountTokens.every((ele) => ele === '')) {
      setMessErr(
        `Please fill in ${tokenPools.length === 1 ? 'this' : 'these'} field${
          tokenPools.length === 1 ? '' : 's'
        }`
      );
    } else if (new BigNumber(+stakingLimit).gt(0)) {
      const totalStakeDivDecimal = totalTokenPools.map((item: any) => {
        return +formatNumberDivDecimals(
          item.totalStake,
          item.decimalStakeToken
        );
      });
      const totalStake = checkSumInput(totalStakeDivDecimal);
      const sumCanStake = new BigNumber(
        formatNumberDivDecimals(stakingLimit, 18)
      )
        .minus(totalStake)
        .toString(10);
      const sumInputToken = checkSumInput(amountTokens);

      if (
        new BigNumber(totalStake).eq(
          new BigNumber(formatNumberDivDecimals(stakingLimit, 18))
        )
      ) {
        setMessErr(
          `The total input stake amount will exceed the pool stake limit. You can only stake ${numberWithCommas(
            truncateNumber(String(sumCanStake), MAX_DECIMAL, false)
          )} for all tokens`
        );
        setIsDisableInput(true);
      } else if (new BigNumber(sumInputToken).gt(new BigNumber(sumCanStake))) {
        setMessErr(
          `The total input stake amount will exceed the pool stake limit. You can only stake ${numberWithCommas(
            truncateNumber(String(sumCanStake), MAX_DECIMAL, false)
          )} for all tokens`
        );
      } else if (new BigNumber(sumInputToken).lte(new BigNumber(sumCanStake))) {
        setIsDisableInput(false);
        setMessErr('');
      }
    } else {
      setMessErr('');
      setIsDisableInput(false);
    }
  }, [stakingLimit, amountTokens, totalTokenPools, tokenPools]);

  useEffect(() => {
    tokenPools.forEach(async (item: any, index: number) => {
      const balanceAmount = await getBalanceToken(
        item.stakeToken.address,
        index
      );
      if (balanceAmount) {
        setBalanceAmounts((prevState) => {
          const newArr = [...prevState];
          newArr[index] = balanceAmount;
          return newArr;
        });
      }
    });
  }, [tokenPools, isOpen]);

  useEffect(() => {
    if (!isStaking) {
      setIsConfirming(false);
    }
  }, [isStaking, tokenPools]);

  useEffect(() => {
    setAmountTokens(initStateArray);
    setAmountTokensDisplay(initStateArray);
    setIsInputChange(false);
    setIsConfirming(false);
    setIsApproving(false);
  }, [isOpen, initStateArray]);

  useEffect(() => {
    if (isStaking && !!txhash) {
      onOpen();
    }
  }, [txhash]);

  return (
    <ModalCustom
      isOpen={isOpen}
      onClickBackIcon={handleBack}
      isShowBackIcon={isConfirming && !isStaking}
      isShowCloseIcon={true}
      onClose={onClose}
      title={isStaking ? undefined : 'Stake'}
      width="448px"
    >
      {isStaking ? (
        <ModalProgressContent
          onClose={onClose}
          currentProgress={currentProgress}
          action={currentAction}
          txhash={txhash}
        />
      ) : (
        <div className="modal-stake">
          {isConfirming ? (
            <>
              <div className="token-info">
                <div className="token">
                  <div>Staked amount:</div>
                  {tokenPools.map((item: TokenPool, index: number) => {
                    return (
                      <div key={index} className="text-token">
                        {numberWithCommas(amountTokensDisplay[index])}{' '}
                        {item.stakeToken.symbolTokenLp ||
                          item.stakeToken.symbol}
                      </div>
                    );
                  })}
                </div>
              </div>
              <ButtonLG widthFull onClick={handleStake}>
                Confirm
              </ButtonLG>
            </>
          ) : (
            <>
              {(numberTokens > 1 || +lockPeriod !== 0) && (
                <div className="modal-stake__notice">
                  {numberTokens && numberTokens > 1 && (
                    <div>
                      *Staking ratio between tokens
                      {tokenPools.map((item: any, index: number) => {
                        return (
                          <span key={index}>
                            : {item.rate}{' '}
                            {item.stakeToken.symbolTokenLp ||
                              item.stakeToken.symbol}{' '}
                          </span>
                        );
                      })}
                    </div>
                  )}
                  {+lockPeriod !== 0 && (
                    <div>
                      *After each staking action, you cannot unstake or claim
                      reward at all in {formatDay(+lockPeriod)} days.
                    </div>
                  )}
                </div>
              )}
              <div className="modal-stake__input-wrapper">
                <>
                  {tokenPools.map((item: TokenPool, index: number) => {
                    return (
                      <InputStaking
                        isDisableInput={isDisableInput}
                        key={item.id}
                        iconToken={item.stakeToken.thumbLogo}
                        stakeToken={item.stakeToken}
                        nameToken={
                          item.stakeToken.symbolTokenLp ||
                          item.stakeToken.symbol
                        }
                        type="stake"
                        value={amountTokensDisplay[index]}
                        onChange={(e: React.FormEvent<HTMLInputElement>) => {
                          handleChange(e.currentTarget.value as string, index);
                          setIsInputChange(true);
                        }}
                        amount={balanceAmounts[index]}
                        onClickMax={() => {
                          handleClickMax(balanceAmounts[index], index);
                        }}
                      />
                    );
                  })}
                </>
                {messErr && !setDisableBtn && (
                  <div className="error">* {messErr}</div>
                )}
              </div>
              <div className="modal-stake__btn-group">
                <>
                  <button className="btn-cancel" onClick={onClose}>
                    Cancel
                  </button>
                  <ButtonLG
                    widthFull
                    disabled={
                      checkSumInput(amountTokens) === 0 ||
                      isApproving ||
                      !!messErr ||
                      setDisableBtn ||
                      amountTokens.some((ele) => +ele === 0)
                    }
                    onClick={() => {
                      setIsConfirming(true);
                    }}
                  >
                    {checkSumInput(amountTokens) === 0
                      ? 'Enter an amount'
                      : 'Stake'}
                  </ButtonLG>
                </>
              </div>
            </>
          )}
        </div>
      )}
    </ModalCustom>
  );
};

export default ModalStake;
