import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import thunk from 'redux-thunk';
import { blackListReducer } from './reducers';
import commonReducer from './reducers/commons';

const store = configureStore({
  reducer: {
    blackList: blackListReducer,
    commons: commonReducer,
  },

  middleware: [thunk],
});

export type RootState = ReturnType<typeof store.getState>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
