import { useWeb3React } from '@web3-react/core';
import { useEffect, useMemo, useState } from 'react';
import { WALLET } from 'utils/constants';
import StorageUtils from 'utils/storage';
import { useAstroneWallet } from 'contexts/AstroneContext';

const useInfoWallet = () => {
  const wallet = StorageUtils.getItem(WALLET);
  const {
    activate,
    account: accountInjected,
    chainId: chainIdInjected,
  } = useWeb3React();
  const {
    loginAstrone,
    account: accountAstrone,
    chainId: chainIdAstrone,
  } = useAstroneWallet();

  const [account, setAccount] = useState<string | null>();
  const [chainId, setChainId] = useState<number>();

  useEffect(() => {
    const getAccount = async () => {
      if (wallet === 'astrone') {
        setAccount(accountAstrone);
      } else if (wallet === 'injected' || wallet === 'walletconnect') {
        setAccount(accountInjected);
      } else {
        setAccount(undefined);
      }
    };

    const getNetwork = async () => {
      if (wallet === 'astrone') {
        setChainId(chainIdAstrone);
      } else if (wallet === 'injected' || wallet === 'walletconnect') {
        setChainId(chainIdInjected);
      } else {
        setChainId(undefined);
      }
    };

    getAccount();
    getNetwork();
  }, [
    activate,
    loginAstrone,
    accountInjected,
    accountAstrone,
    chainIdInjected,
    chainIdAstrone,
  ]);

  const context = useMemo(() => {
    return {
      account,
      chainId,
    };
  }, [account, chainId]);

  return context;
};
export default useInfoWallet;
