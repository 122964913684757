import ModalCustom from 'components/Modal';
import { useSwitchNetwork } from 'hooks/useSwitchNetwork';
import { CHAIN_INFO, SUPPORTED_CHAIN_IDS } from 'utils/constants/chains';
import './style.scss';

interface ModalSwitchNetworkProps {
  isOpen: boolean;
  onClose: () => void;
}

const ModalSwitchNetwork: React.FC<ModalSwitchNetworkProps> = ({
  isOpen,
  onClose,
}) => {
  const { trySwitchNetworks } = useSwitchNetwork();

  return (
    <ModalCustom
      isOpen={isOpen}
      isShowCloseIcon={true}
      onClose={onClose}
      title="Switch network"
    >
      <div className="modal-switch">
        {SUPPORTED_CHAIN_IDS.map((chainId) => {
          const chain = CHAIN_INFO[chainId];
          return (
            <div
              className="modal-switch__item"
              key={chain.hexChainId}
              onClick={() => {
                trySwitchNetworks(Number(chain.hexChainId?.toString()));
                onClose();
              }}
            >
              <img src={chain.icon} alt="" />
              <div>{chain.label}</div>
            </div>
          );
        })}
      </div>
    </ModalCustom>
  );
};

export default ModalSwitchNetwork;
