import { Avatar, Col, Layout, Row, Space } from 'antd';
import { Stake } from './Stake';
import DefaultToken from '../../../assets/images/icons/default_token.svg';
import './style.scss';
import '../../../styles/common.scss';
import { BREAKPOINT } from 'utils/constants/breakpoint';
import { useWindowSize } from 'hooks/useWindowSize';
import { Pool as IPool, TokenPool } from 'interfaces/Pools/Pool';
import { zip } from 'utils/function';
import React from 'react';
import moment from 'moment';

const { Content } = Layout;

interface Props {
  data: IPool;
  onClickStake: () => void;
}

export const Pool = (props: Props) => {
  const { data, onClickStake } = props;
  const width = useWindowSize();

  return (
    <Content className="site-layout-background">
      <Space>
        <Row>
          <Col className="pool-tokens">
            <div className="logo-wrapper">
              {data.tokenPools
                ? zip<TokenPool, String>(
                    data.tokenPools,
                    props.data.stakeTokens.split('-')
                  ).map((tokenInfo, index) => {
                    const [token, tokenName] = tokenInfo;
                    const { largeLogo, token0, token1 } = token?.stakeToken;
                    const singleToken = (
                      <div className="logo-info" key={index}>
                        <img
                          src={largeLogo || DefaultToken}
                          alt="logo"
                          className="logo-info__icon-token-single"
                        />
                        <div className="fw-700 titleText" key={token.id}>
                          {tokenName}
                        </div>
                      </div>
                    );
                    const lpToken = (
                      <div className="logo-info" key={index}>
                        <div className="logo-info__icon-token-lp" key={index}>
                          <img
                            src={token0?.largeLogo || DefaultToken}
                            alt="logo"
                            className="logo-info__icon-token-lp__first"
                          />
                          <img
                            src={token1?.largeLogo || DefaultToken}
                            alt="logo"
                            className="logo-info__icon-token-lp__second"
                          />
                        </div>
                        <div className="fw-700 titleText" key={token.id}>
                          {tokenName.replaceAll('/', '-')} LP
                        </div>
                      </div>
                    );
                    if (token0) return lpToken;
                    else return singleToken;
                  })
                : null}
            </div>
            <Col>
              <div className={`fw-700 title create-date`}>
                {moment
                  .unix(new Date(props.data.createdAt).getTime() / 1000)
                  .format('MM.DD')}
              </div>
              <div className={`fw-700 title ${props.data.chain}`}>
                {props.data.chain}
              </div>
            </Col>
          </Col>
        </Row>
      </Space>
      <Stake
        onClickClaim={() => {}}
        onClickUnstake={onClickStake}
        onClickStake={onClickStake}
        pool={data}
      />
    </Content>
  );
};
