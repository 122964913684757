import BigNumber from 'bignumber.js';

export const formatNumberDivDecimals = (
  value: string | number,
  decimals: number = 0
) => {
  const valueBigNumber = new BigNumber(value);

  if (valueBigNumber.eq(0) || valueBigNumber.toString() === 'NaN') return '0';
  if (valueBigNumber.lt(0.000001)) return '<0.000001';

  return valueBigNumber.div(new BigNumber(10).pow(decimals)).toString();
};

export function numberWithCommas(value: string) {
  const formatValue = new BigNumber(value).toString(10);
  if (
    BigNumber(formatValue).eq(0) ||
    BigNumber(formatValue).toString() === 'NaN'
  )
    return '0';
  const parts = formatValue.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}

export function shortNumber(value: number | string) {
  const parts = value.toString().split('.');
  if (!!parts[0] && parts[0].length > 17) {
    return `${value.toString().substring(0, 17)}...`;
  } else {
    return value;
  }
}
