import Header from 'components/layout/Header';
import Footer from './Footer';
import './styles/layout.scss';

interface LayoutProps {
  children: React.ReactElement;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className="main">
      <Header />
      <div className="content-wrapper">
        <div className="content">{children}</div>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
