import { useAstroneWallet } from 'contexts/AstroneContext';
import { useEffect, useState } from 'react';
import {
  ConnectorNames,
  injectedConnector,
  walletconnectConnector,
} from 'utils/constants/connectors';
import { useWeb3React } from '@web3-react/core';
import StorageUtils from 'utils/storage';
import { WALLET, WALLETCONNECT } from 'utils/constants';

export function useEagerConnect() {
  const { activate, active } = useWeb3React();
  const { loginAstrone } = useAstroneWallet();
  const [tried, setTried] = useState(false);
  const wallet = StorageUtils.getItem(WALLET);
  const isConnectWalletconnect = StorageUtils.getItem(WALLETCONNECT);

  useEffect(() => {
    if (!active) {
      injectedConnector.isAuthorized().then((isAuthorized) => {
        if (
          wallet === ConnectorNames.WalletConnect &&
          !!isConnectWalletconnect
        ) {
          activate(walletconnectConnector, undefined, true).catch(() => {
            setTried(true);
          });
        } else {
          setTried(true);
        }
        if (isAuthorized) {
          if (wallet === ConnectorNames.Injected) {
            activate(injectedConnector, undefined, true).catch(() => {
              setTried(true);
            });
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activate, wallet]); // intentionally only running on mount (make sure it's only mounted once ::)

  // wait until we get confirmation of a connection to flip the flag
  useEffect(() => {
    if (active) {
      setTried(true);
    }
  }, [active]);

  return tried;
}
