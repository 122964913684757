import { useWindowSize } from 'hooks/useWindowSize';
import { BREAKPOINT } from 'utils/constants/breakpoint';
import footerLogoDesktop from 'assets/images/footer-logo.svg';
import footerLogoMobile from 'assets/images/footer-logo-mobile.svg';
import './styles/footer.scss';

const Footer = () => {
  const width = useWindowSize();

  return (
    <footer className="footer-wrapper">
      <div className="footer">
        <div className="logo-wrapper">
          <img
            src={
              width > BREAKPOINT.SMALL ? footerLogoDesktop : footerLogoMobile
            }
            alt="logo"
            className="footer-logo"
          />
          <div className="beta">Beta</div>
        </div>
        <div className="footer__powered-by">Powered by Protocon</div>
      </div>
    </footer>
  );
};

export default Footer;
