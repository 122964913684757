import { Tooltip } from 'antd';
import BigNumber from 'bignumber.js';
import { formatLargeNumber } from 'helpers/formatLargeNumber';
import { numberWithCommas } from 'helpers/formatNumber';
import { truncateNumber } from 'helpers/truncateNumber';
import { useEffect, useState } from 'react';

interface Props {
  percentValue: string;
}

export const PercentWithHover = ({ percentValue }: Props) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const elements = document.getElementsByClassName('content-wrapper');
    const func = () => {
      setOpen(false);
    };
    if (elements.length > 0) {
      elements[0].addEventListener('scroll', func);
    }
    return () => {
      if (elements.length > 0) {
        elements[0].removeEventListener('scroll', func);
      }
    };
  }, []);

  const formatNumber =
    formatLargeNumber(truncateNumber(percentValue, 2, false)) ||
    numberWithCommas(truncateNumber(percentValue, 2, false));

  return (
    <Tooltip
      placement="bottom"
      open={open}
      title={numberWithCommas(new BigNumber(percentValue).toString(10))}
      color="white"
      overlayClassName="pool-tooltip"
      onOpenChange={() => {
        if (String(formatNumber) !== String(numberWithCommas(percentValue))) {
          setOpen(!open);
        }
      }}
    >
      <div className="fw-700 title APR">{formatNumber}%</div>
    </Tooltip>
  );
};
