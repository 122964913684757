import ButtonLG from 'components/common/ButtonLG';
import ModalCustom from 'components/Modal';
import './style.scss';

interface ModalNoticeProps {
  isOpen: boolean;
  onClose: () => void;
  onStake: () => void;
}

const ModalNotice: React.FC<ModalNoticeProps> = ({
  isOpen,
  onClose,
  onStake,
}) => {
  return (
    <ModalCustom
      isOpen={isOpen}
      isShowCloseIcon={true}
      onClose={onClose}
      title="Notice"
      width="448px"
    >
      {
        <div className="modal-notice">
          <div>
            If you stake additional tokens, lock period for the existing amount
            has been reset and starts again from the day of additional staking.
            Are you sure to stake?
          </div>
          <ButtonLG
            widthFull
            onClick={() => {
              onStake();
              onClose();
            }}
          >
            Stake
          </ButtonLG>
        </div>
      }
    </ModalCustom>
  );
};

export default ModalNotice;
