import ALLOCATION_POOL_ABI from 'abi/ALLOCATION_POOL.json';
import LINEAR_POOL_ABI from 'abi/LINEAR_POOL.json';
import ButtonLG from 'components/common/ButtonLG';
import { BalanceWithHover } from 'components/common/Text/BalanceWithHover';
import ModalCustom from 'components/Modal';
import ModalProgressContent, {
  TypeAction,
  TypeProgress,
} from 'components/ModalProgress';
import { getRPC } from 'helpers/getContract';
import { useContract } from 'hooks/useContract';
import useInfoWallet from 'hooks/useInfoWallet';
import { Pool, TokenPool } from 'interfaces/Pools/Pool';
import { useEffect, useState } from 'react';
import { WALLET } from 'utils/constants';
import { ConnectorNames } from 'utils/constants/connectors';
import StorageUtils from 'utils/storage';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils';
import './style.scss';

interface ModalClaimProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onClaimSuccessfully: () => void;
  selectedPool: Pool;
  rewards: string[];
}

const ModalClaim: React.FC<ModalClaimProps> = ({
  isOpen,
  onOpen,
  onClose,
  selectedPool,
  rewards,
  onClaimSuccessfully,
}) => {
  const { account, chainId } = useInfoWallet();
  const [currentProgress, setCurrentProgress] = useState<TypeProgress>('');
  const [currentAction, setCurrentAction] = useState<TypeAction>('');
  const [txhash, setTxhash] = useState<string>('');
  const [isStaking, setIsStaking] = useState(false);
  const wallet = StorageUtils.getItem(WALLET);
  const { astrone } = window as any;
  const web3 = new Web3(getRPC(Number(chainId)));

  const poolContract = useContract(
    selectedPool.aprType === 'FIXED'
      ? (LINEAR_POOL_ABI as AbiItem[])
      : (ALLOCATION_POOL_ABI as AbiItem[]),
    selectedPool.address
  );

  const handleClaim = async () => {
    if (wallet === ConnectorNames.Astrone) {
      setIsStaking(true);
      setCurrentProgress('progressing');
      setCurrentAction('claim');

      const poolContractWeb3 = new web3.eth.Contract(
        selectedPool.aprType === 'FIXED'
          ? (LINEAR_POOL_ABI as AbiItem[])
          : (ALLOCATION_POOL_ABI as AbiItem[]),
        selectedPool.address
      );

      try {
        const dataEncodeABI =
          selectedPool.aprType === 'FIXED'
            ? await poolContractWeb3.methods.linearClaimReward().encodeABI()
            : await poolContractWeb3.methods.claimRewards().encodeABI();
        const estimateGas =
          selectedPool.aprType === 'FIXED'
            ? await poolContractWeb3.methods.linearClaimReward().estimateGas({
                from: account,
              })
            : await poolContractWeb3.methods.claimRewards().estimateGas({
                from: account,
              });
        const gasPrice = await web3.eth.getGasPrice();
        const nonce = await web3.eth.getTransactionCount(
          account?.toLowerCase() as string
        );
        const claimInfo = await astrone.request({
          method: 'eth_sendTransaction',
          data: {
            params: {
              from: account,
              to: selectedPool.address,
              gas: estimateGas,
              gasPrice,
              value: '0x0',
              data: dataEncodeABI,
              nonce,
            },
            type: 'claim',
          },
        });

        if (claimInfo.status) {
          setCurrentProgress('successfully');
          setTxhash(claimInfo.transactionHash);
        } else {
          setCurrentProgress('failed');
        }
      } catch (error) {
        setCurrentProgress('failed');
        onOpen();
        console.error(error);
      }
    } else {
      if (poolContract) {
        setIsStaking(true);
        setCurrentProgress('progressing');
        setCurrentAction('claim');

        try {
          let claimInfo;
          if (selectedPool.aprType === 'FIXED') {
            claimInfo = await poolContract.methods
              .linearClaimReward()
              .send({ from: account });
          } else {
            claimInfo = await poolContract.methods
              .claimRewards()
              .send({ from: account });
          }
          if (claimInfo.status) {
            setCurrentProgress('successfully');
            setTxhash(claimInfo.transactionHash);
          } else {
            setCurrentProgress('failed');
          }
          onClaimSuccessfully();
        } catch (error) {
          setCurrentProgress('failed');
          onOpen();
          console.error(error);
        }
      }
    }
  };

  useEffect(() => {
    if (isStaking && !!txhash) {
      onOpen();
    }
  }, [isStaking, txhash]);

  return (
    <ModalCustom
      isOpen={isOpen}
      isShowBackIcon={false}
      isShowCloseIcon={true}
      onClose={onClose}
      title={isStaking ? undefined : 'Claim'}
      width="448px"
    >
      {isStaking ? (
        <ModalProgressContent
          onClose={onClose}
          currentProgress={currentProgress}
          action={currentAction}
          txhash={txhash}
        />
      ) : (
        <>
          <div className="modal-claim">
            <div>Claim amount:</div>
            {selectedPool.tokenPools.map((item: TokenPool, index: number) => {
              return (
                <div key={index}>
                  <BalanceWithHover
                    key={index}
                    value={rewards[index]}
                    decimal={item.rewardToken.decimal}
                    className="text-claim"
                    symbol={
                      item.rewardToken.symbolTokenLp || item.rewardToken.symbol
                    }
                  />
                </div>
              );
            })}
          </div>
          <ButtonLG widthFull onClick={handleClaim}>
            Confirm
          </ButtonLG>
        </>
      )}
    </ModalCustom>
  );
};

export default ModalClaim;
