import { Tooltip } from 'antd';
import BigNumber from 'bignumber.js';
import { formatLargeNumber } from 'helpers/formatLargeNumber';
import { numberWithCommas } from 'helpers/formatNumber';
import { truncateNumber } from 'helpers/truncateNumber';
import { useEffect, useState } from 'react';

interface Props {
  value: string;
  decimal: number;
  symbol?: string;
  className?: string;
}

export const UsdTextWithHover = ({
  value,
  decimal,
  className,
  symbol,
}: Props) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const elements = document.getElementsByClassName('content-wrapper');
    const func = () => {
      setOpen(false);
    };
    if (elements.length > 0) {
      elements[0].addEventListener('scroll', func);
    }
    return () => {
      if (elements.length > 0) {
        elements[0].removeEventListener('scroll', func);
      }
    };
  }, []);

  const handleFormatNumber = (value: string, afterDecimalLength = 2) => {
    const amount = truncateNumber(
      BigNumber(value)
        .div(Number(`1e${decimal}`))
        .toString(),
      afterDecimalLength,
      false
    );
    return formatLargeNumber(amount) || numberWithCommas(amount);
  };
  return (
    <Tooltip
      placement="bottom"
      open={open}
      title={numberWithCommas(
        BigNumber(value)
          .div(Number(`1e${decimal}`))
          .toString(10)
      )}
      color="white"
      overlayClassName="pool-tooltip"
      onOpenChange={() => {
        if (
          !BigNumber(handleFormatNumber(value).replaceAll(',', '')).eq(
            BigNumber(value).div(Number(`1e${decimal}`).toFixed(12))
          )
        ) {
          setOpen(!open);
        }
      }}
    >
      <div className={className}>{`$ ${handleFormatNumber(value)}`}</div>
    </Tooltip>
  );
};
