import React from 'react';

import Spinner from './Spinner';

const TableLoading: React.FC = () => {
  return (
    <div className="no-record">
      <div>
        <Spinner />
      </div>
    </div>
  );
};

export default TableLoading;
