import './style.scss';

interface AprTagProps {
  type: string;
}

const AprTag: React.FC<AprTagProps> = ({ type }) => {
  return (
    <div
      className={`apr-tag ${type === 'FIXED' ? 'type-fixed' : 'type-dynamic'}`}
    >
      {type === 'FIXED' ? 'Fixed' : 'Dynamic'}
    </div>
  );
};

export default AprTag;
