import { Pagination } from 'antd';
import Disconnected from 'components/common/Disconnected';
import NoRecord from 'components/common/NoRecord';
import { PersonalPool } from 'components/modules/pool/PersonalPool';
import { ITEM_PER_PAGE } from 'utils/constants';
import { Pool as IPool, Pool } from 'interfaces/Pools/Pool';
import TableLoading from 'components/common/TableLoading';
import { SUPPORTED_CHAIN_IDS } from 'utils/constants/chains';
import TableWrongNetwork from 'components/common/TableWrongNetwork';

interface Props {
  pools?: IPool[];
  currentPage: number;
  totalRecord: number;
  onChangePage: (newPage: number) => void;
  onClickStake: (refreshFunc: () => void) => void;
  onClickUnstake: (refreshFunc: () => void) => void;
  onOpenModalNotice: () => void;
  setCurrentPool: (pool: IPool) => void;
  loading?: boolean;
  isConnected?: boolean;
  chainId?: number;
}

const MyPool = (props: Props) => {
  const {
    pools,
    currentPage,
    totalRecord,
    onChangePage,
    isConnected = false,
    onClickStake,
    onClickUnstake,
    onOpenModalNotice,
    setCurrentPool,
    loading,
    chainId,
  } = props;

  const isSupportedNetwork = SUPPORTED_CHAIN_IDS.includes(Number(chainId));
  if (chainId && !isSupportedNetwork) {
    return <TableWrongNetwork />;
  }

  if (!pools && loading) {
    return <TableLoading />;
  }

  return (
    <>
      {isConnected ? (
        <>
          {pools && pools.length ? (
            pools.map((pool: Pool) => (
              <PersonalPool
                onClickStake={(refreshPoolFunc: () => void) => {
                  onOpenModalNotice();
                  setCurrentPool(pool);
                }}
                onClickUnstake={(refreshPoolFunc: () => void) => {
                  onClickUnstake(refreshPoolFunc);
                  setCurrentPool(pool);
                }}
                data={pool}
                key={pool.id}
              />
            ))
          ) : (
            <NoRecord />
          )}
          {totalRecord > ITEM_PER_PAGE && !!pools?.length && (
            <Pagination
              total={totalRecord}
              onChange={onChangePage}
              current={currentPage}
              pageSize={ITEM_PER_PAGE}
            />
          )}
        </>
      ) : (
        <Disconnected />
      )}
    </>
  );
};

export default MyPool;
