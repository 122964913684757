import { Web3ReactProvider } from '@web3-react/core';
import 'antd/dist/antd.min.css';
import AppContainer from 'components/layout/AppContainer';
import { AstroneProvider } from 'contexts/AstroneContext';
import { Provider } from 'react-redux';
import store from 'store';
import 'styles/override.scss';
import Web3 from 'web3';

function getLibrary(provider: any): Web3 {
  return new Web3(provider);
}

function App() {
  return (
    <Provider store={store}>
      <AstroneProvider>
        <Web3ReactProvider getLibrary={getLibrary}>
          <AppContainer />
        </Web3ReactProvider>
      </AstroneProvider>
    </Provider>
  );
}

export default App;
