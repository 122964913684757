import { InfoCircleOutlined } from '@ant-design/icons';
import { Avatar, Col } from 'antd';
import BigNumber from 'bignumber.js';
import Buttons from 'components/common/Button';
import { PercentWithHover } from 'components/common/Text';
import { BalanceWithHover } from 'components/common/Text/BalanceWithHover';
import TooltipBase from 'components/common/Tooltip';
import { PoolStatusEnum } from 'interfaces';
import { Pool, TokenPool } from 'interfaces/Pools/Pool';
import moment from 'moment';
import { useWindowSize } from 'hooks/useWindowSize';
import { BREAKPOINT } from 'utils/constants/breakpoint';
import { SUPPORTED_CHAIN_IDS } from 'utils/constants/chains';
import { formatDay } from 'utils/function';
import ClaimStake from './Claim';
import get from 'lodash/get';
import { UsdTextWithHover } from 'components/common/Text/UsdTextWithHover';
import DefaultToken from '../../../assets/images/icons/default_token.svg';
import useInfoWallet from 'hooks/useInfoWallet';
import AprTag from 'components/AprTag';

interface StakeProps {
  pool: Pool;
  rewards?: string[];
  stakedAmounts?: string[];
  isPersonalPool?: Boolean;
  isStakeMode?: Boolean;
  isDisabled?: boolean;
  onClickStake: () => void;
  onClickUnstake: () => void;
  onClickClaim: () => void;
}

export const Stake: React.FC<StakeProps> = (props: StakeProps) => {
  const {
    pool,
    rewards,
    isPersonalPool,
    isStakeMode = true,
    isDisabled,
    onClickStake,
    onClickUnstake,
    onClickClaim,
    stakedAmounts,
  } = props;
  const { tokenPools, userPool } = pool;
  // TODO: check chain to allow stake, unstake
  const { chainId, account } = useInfoWallet();
  const width = useWindowSize();

  const nameChain =
    chainId === Number(process.env.REACT_APP_KCC_CHAIN_ID) ? 'KCC' : 'BSC';

  const unstakeTokenAvailable = (): Boolean => {
    if (stakedAmounts) {
      return !stakedAmounts.map((item) => item === '0').includes(true);
    }
    return false;
  };

  const renderStakeButton = (
    isStakeMode: boolean,
    account: string | null | undefined,
    chainId?: number
  ) => {
    if (isStakeMode) {
      if (account) {
        if (
          pool.status === PoolStatusEnum.ENDED ||
          (!(pool.aprType === 'DYNAMIC') &&
            pool.stakingLimit === pool.totalStake)
        ) {
          return (
            <TooltipBase
              placement="bottom"
              title="You can no longer stake into this pool."
              color="white"
              overlayClassName="pool-tooltip"
            >
              <Buttons
                type="default"
                onClick={() => {}}
                tabIndex={-1}
                className={'fw-700 lg-color justify-end btn-stake btn-disabled'}
              >
                Stake
              </Buttons>
            </TooltipBase>
          );
        }
        if (
          !SUPPORTED_CHAIN_IDS.includes(Number(chainId)) ||
          pool.chain !== nameChain
        ) {
          return (
            <TooltipBase
              placement="bottom"
              title={`Wrong chain. Please switch to ${pool.chain} to stake into this pool`}
              color="white"
              overlayClassName="pool-tooltip"
            >
              <Buttons
                type="default"
                onClick={() => {}}
                tabIndex={-1}
                className={'fw-700 lg-color justify-end btn-stake btn-disabled'}
              >
                Stake
              </Buttons>
            </TooltipBase>
          );
        }
        return (
          <Buttons
            type="default"
            onClick={onClickStake}
            tabIndex={isDisabled ? -1 : undefined}
            className={`fw-700 lg-color justify-end btn-stake ${
              !account || isDisabled ? 'btn-disabled' : ''
            }`}
          >
            Stake
          </Buttons>
        );
      }
      // khi chưa connect
      return (
        <TooltipBase
          placement="bottom"
          title="Please connect wallet to continue"
          color="white"
          overlayClassName="pool-tooltip"
        >
          <Buttons
            tabIndex={-1}
            type="default"
            onClick={() => {}}
            className={'fw-700 lg-color justify-end btn-stake btn-disabled'}
          >
            Stake
          </Buttons>
        </TooltipBase>
      );
    }
    // nếu khác mạng và unstake
    if (pool.chain !== nameChain) {
      return (
        <TooltipBase
          placement="bottom"
          title={`Wrong chain. Please switch to ${pool.chain} to unstake into this pool`}
          color="white"
          overlayClassName="pool-tooltip"
        >
          <Buttons
            tabIndex={-1}
            type="default"
            onClick={() => {}}
            className={'fw-700 lg-color justify-end btn-stake btn-disabled'}
          >
            Unstake
          </Buttons>
        </TooltipBase>
      );
    }
    const isInLockTime = BigNumber(
      BigNumber(userPool.startStake).plus(pool.lockPeriod).toNumber()
    ).gt(moment().unix());

    if (isInLockTime) {
      return (
        <TooltipBase
          placement="bottom"
          title={`You cannot unstake before ${moment
            .unix(
              new BigNumber(userPool.startStake)
                .plus(pool.lockPeriod)
                .toNumber()
            )
            .format('HH:mm DD/MM/YYYY')}`}
          color="white"
          overlayClassName="pool-tooltip"
        >
          <Buttons
            tabIndex={-1}
            type="default"
            onClick={() => {}}
            className={'fw-700 lg-color justify-end btn-stake btn-disabled'}
          >
            Unstake
          </Buttons>
        </TooltipBase>
      );
    }
    if (!unstakeTokenAvailable()) {
      return (
        <Buttons
          tabIndex={-1}
          type="default"
          onClick={() => {}}
          className={'fw-700 lg-color justify-end btn-stake btn-disabled'}
        >
          Unstake
        </Buttons>
      );
    }
    return (
      <Buttons
        type="default"
        onClick={onClickUnstake}
        className="fw-700  lg-color justify-end btn-stake"
      >
        Unstake
      </Buttons>
    );
  };

  const renderLimitStakeTokenAmount = () => {
    return (
      <>
        <div className="fw-500 text-title">
          Stake limit
          <TooltipBase
            placement="bottom"
            title="The maximum amount of token can be staked into this pool"
            color="white"
            overlayClassName="pool-tooltip"
          >
            <InfoCircleOutlined />
          </TooltipBase>
        </div>
        {pool.stakingLimit !== '0' ? (
          <div
            style={{
              width: 'fit-content',
            }}
          >
            <BalanceWithHover
              className="fw-700 title"
              value={pool.stakingLimit}
              decimal={18}
              text={`(${new BigNumber(pool.totalStake)
                .div(pool.stakingLimit)
                .multipliedBy(100)
                .decimalPlaces(0, 1)}%)`}
            />
          </div>
        ) : (
          <div className="fw-700 title">-</div>
        )}
      </>
    );
  };

  const calculatePoolLiquidity = (pool: Pool) => {
    const tokens = pool.tokenPools.map((item) => {
      return {
        usdPrice: get(item, 'stakeToken.usdPrice', 0),
        amount: BigNumber(item.totalStake).div(
          Number(`1e${item.stakeToken.decimal}`)
        ),
      };
    });

    const { totalLiquidity } = tokens.reduce(
      ({ isValid, totalLiquidity }, item) => {
        const liquidity = BigNumber(item.amount)
          .multipliedBy(item.usdPrice)
          .toString();
        if (liquidity !== '0' && isValid) {
          return {
            isValid: true,
            totalLiquidity: BigNumber(totalLiquidity)
              .plus(liquidity)
              .toString(),
          };
        }
        return {
          isValid: false,
          totalLiquidity: '0',
        };
      },
      {
        isValid: true,
        totalLiquidity: '0',
      }
    );
    return totalLiquidity;
  };

  return (
    <>
      <div className="d-flex justify-between Stake-container">
        <div className="d-flex flex-align-center Stake-left ">
          <Col span={5} className="btn-stake-mob-helper">
            <div className="token-stake-wrapper">
              {isPersonalPool ? (
                <StakedTokens
                  tokensPools={tokenPools}
                  stakedAmounts={stakedAmounts}
                />
              ) : (
                <Reward tokenPools={tokenPools} />
              )}
            </div>
            {width <= BREAKPOINT.MEDIUM && (
              <div className="btn-stake-wrapper">
                {renderStakeButton(Boolean(isStakeMode), account, chainId)}
              </div>
            )}
          </Col>
          <Col span={4}>
            <div className="fw-500 text-title">
              APR
              <AprTag type={pool.aprType} />
            </div>
            <div className="d-flex justify-start">
              <PercentWithHover percentValue={pool.apr} />
            </div>
          </Col>
          <Col span={4}>
            <div className="fw-500 text-title">
              Liquidity
              <TooltipBase
                placement="bottom"
                title="Total value of funds in this liquidity pool"
                color="white"
                overlayClassName="pool-tooltip"
              >
                <InfoCircleOutlined />
              </TooltipBase>
            </div>
            {
              <div
                style={{
                  width: 'fit-content',
                }}
              >
                <UsdTextWithHover
                  className="fw-700 title"
                  value={calculatePoolLiquidity(pool)}
                  decimal={0}
                />
              </div>
            }
          </Col>
          <Col span={4}>
            <div className="fw-500 text-title">
              Lock period
              <TooltipBase
                placement="bottom"
                title={`After each staking action, you cannot unstake or claim reward at all in ${
                  pool.lockPeriod === '0'
                    ? '- days'
                    : formatDay(pool.lockPeriod) + ' days'
                }`}
                color="white"
                overlayClassName="pool-tooltip"
              >
                <InfoCircleOutlined />
              </TooltipBase>
            </div>
            <div className="fw-700 title">
              {pool.lockPeriod === '0' ? (
                '-'
              ) : (
                <BalanceWithHover
                  value={formatDay(pool.lockPeriod)}
                  decimal={0}
                  symbol={'days'}
                  enableHover={false}
                />
              )}
            </div>
          </Col>
          <Col span={3.5}>
            {pool.aprType === 'DYNAMIC' ? (
              <>
                <div className="fw-500 text-title">
                  Multiplier
                  <TooltipBase
                    placement="bottom"
                    title="The multiplier of token rewards each pool receives"
                    color="white"
                    overlayClassName="pool-tooltip"
                  >
                    <InfoCircleOutlined />
                  </TooltipBase>
                </div>
                <BalanceWithHover
                  className="fw-700 title"
                  value={String(pool.userMultiplier)}
                  decimal={0}
                  addSpace={false}
                  symbol={'x'}
                />
              </>
            ) : (
              renderLimitStakeTokenAmount()
            )}
          </Col>
          {width > BREAKPOINT.MEDIUM && (
            <Col span={3.5}>
              <div className="Stake d-flex justify-end flex-wrap">
                {renderStakeButton(Boolean(isStakeMode), account, chainId)}
              </div>
            </Col>
          )}
        </div>
      </div>
      {isPersonalPool ? (
        <>
          <ClaimStake
            poolChain={pool.chain}
            onClickClaim={onClickClaim}
            userPool={userPool}
            tokenPools={tokenPools}
            reward={rewards}
            lockPeriod={pool.lockPeriod}
          />
        </>
      ) : null}
    </>
  );
};

const Reward = (props: { tokenPools: TokenPool[] }) => {
  const { tokenPools } = props;

  return (
    <>
      <div className="fw-500 text-title">Reward</div>
      <div className="fw-700 title">
        <div className={`reward-tokens tokens-${tokenPools.length}`}>
          {tokenPools
            ? tokenPools.map((token: TokenPool, index: number) => {
                const { largeLogo, token0, token1 } = token?.rewardToken;
                const singleToken = (
                  <div
                    className="d-flex flex-align-center"
                    key={`${token.poolId}${index}`}
                  >
                    <Avatar src={largeLogo || DefaultToken} size={20} />
                    {token.rewardToken.symbolTokenLp.replaceAll('/', '-') ||
                      token.rewardToken.symbol}
                  </div>
                );
                const lpToken = (
                  <div className="reward-tokens__icon-token-lp" key={index}>
                    <img
                      src={token0?.largeLogo || DefaultToken}
                      alt="logo"
                      className="reward-tokens__icon-token-lp__first"
                    />
                    <img
                      src={token1?.largeLogo || DefaultToken}
                      alt="logo"
                      className="reward-tokens__icon-token-lp__second"
                    />
                    <p className="reward-tokens__icon-token-lp__symbol">
                      {token.rewardToken.symbolTokenLp
                        .replaceAll('/', '-')
                        .concat(' LP')}
                    </p>
                  </div>
                );
                if (token0) return lpToken;
                else return singleToken;
              })
            : null}
        </div>
      </div>
    </>
  );
};

const StakedTokens = (props: {
  tokensPools: TokenPool[];
  stakedAmounts?: string[];
}) => {
  const { tokensPools, stakedAmounts } = props;
  const width = useWindowSize();

  return (
    <>
      <div className="fw-500 text-title">My stake</div>
      {width > BREAKPOINT.MEDIUM ? (
        <div className="fw-700 title">
          <div className="my-stake">
            {tokensPools.length &&
              !!stakedAmounts &&
              tokensPools.map((token: TokenPool, index: number) => {
                return (
                  <BalanceWithHover
                    key={`${token.id}${index}`}
                    className="fw-700"
                    value={stakedAmounts[index] || '0'}
                    decimal={token.stakeToken.decimal}
                    symbol={
                      token.stakeToken.symbolTokenLp
                        ? token.stakeToken.symbolTokenLp
                            .replaceAll('/', '-')
                            .concat(' LP')
                        : token.stakeToken.symbol
                    }
                  />
                );
              })}
          </div>
        </div>
      ) : (
        <div className="my-stake-wrapper">
          {tokensPools.length &&
            !!stakedAmounts &&
            tokensPools.map((token: TokenPool, index: number) => (
              <div className="my-stake-item" key={index}>
                <div>
                  {token.stakeToken.symbolTokenLp
                    ? token.stakeToken.symbolTokenLp
                        .replaceAll('/', '-')
                        .concat(' LP')
                    : token.stakeToken.symbol}
                </div>
                <BalanceWithHover
                  key={`${token.id}${index}`}
                  className="fw-700"
                  value={stakedAmounts[index] || '0'}
                  decimal={token.stakeToken.decimal}
                />
              </div>
            ))}
        </div>
      )}
    </>
  );
};
